import {alpha, Box, useTheme} from "@mui/material";
import React from "react";
import {ConfigContentWidth, useDesktopLargePlus, useMobile} from "./common/Extensions";

interface ContentBoxProps {
  /** Children to display inside the box */
  children: React.ReactNode;
  /** A replacement for background color */
  backgroundColor?: string;
  /** An optional name for the background image */
  image?: string;
  /** When true, the content box will adjust height to it's contents */
  unlockHeight?: boolean;
}

/** A centered box with optional background that keeps content an expected width */
export const ContentBox: React.FC<ContentBoxProps> = ({children, backgroundColor, image, unlockHeight = false}) => {
  const theme = useTheme();
  const isMobile = useMobile();
  const isWidescreen = useDesktopLargePlus();
  const imagePath = React.useMemo(() => {
    if (image) {
      const deviceSuffix = isMobile ? "mobile" : "desktop";
      return `url("/images/box/${image}-${deviceSuffix}.png")`;
    } else {
      return "none";
    }
  }, [image, isMobile]);
  const backgroundColorInner = React.useMemo(() => {
    if (backgroundColor) {
      if (backgroundColor === "white") {
        return theme.palette.common.white;
      } else {
        return backgroundColor;
      }
    } else {
      return alpha("#151d28", 0.5);
    }
  }, [backgroundColor]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        paddingX: theme.spacing(isMobile ? 2 : 5),
        paddingY: theme.spacing(5),
        boxShadow: theme.shadows[5],
        minHeight: unlockHeight ? "inherit" : "500px",
        height: unlockHeight ? "inherit" : "500px",
        width: isWidescreen ? `${ConfigContentWidth}px` : "100%",
        zIndex: 1,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: alpha(theme.palette.common.white, 0.15),
        backgroundImage: imagePath,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",

        backgroundColor: backgroundColorInner,
      }}
    >
      {children}
    </Box>
  );
};
