import {OverrideFunction} from "./types";
import {alpha} from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    white: true;
    transparent: true;
    light: true;
  }
}

export const buttonOverride: OverrideFunction<"MuiButton"> = theme => ({
  variants: [
    {
      props: {variant: "white"},
      style: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        boxShadow: theme.shadows[2],
        "&:hover": {backgroundColor: "white", boxShadow: theme.shadows[4]},
        "&:active": {boxShadow: theme.shadows[8]},
      },
    },
    {
      props: {variant: "transparent"},
      style: {
        backgroundColor: "transparent",
        color: theme.palette.common.white,
        "&:hover": {backgroundColor: alpha(theme.palette.common.white, 0.25)},
      },
    },
  ],
});
