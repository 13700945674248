import React from "react";
import {alpha, Autocomplete, AutocompleteRenderInputParams, Box, Stack, TextField, Typography, useTheme} from "@mui/material";
import {ConfigSearchMaxWidth, useMobile} from "./Extensions";
import {useDebounce} from "use-debounce";
import {ProjectData, projectThumbnailUrl} from "../../data/ProjectData";
import {projectPageUrl, searchPageUrl} from "../../RadiantRouter";
import {useAppDispatch, useAppSelector} from "./Global";
import {selectFoundProjects, selectFoundTags, setSearch} from "../../pages/common/SearchState";
import {useGoto} from "../../pages/common/NavigationState";

/** Search field for website content at the top of the page */
export const SearchField = () => {
  const theme = useTheme();
  const foundProjects = useAppSelector(selectFoundProjects);
  const foundTags = useAppSelector(selectFoundTags);

  // Store Autocomplete value to track when it changes for redirect
  const [autocompleteValue, setAutocompleteValue] = React.useState<ProjectData | string | null>(null);

  // Store TextField to debounce it and set the debounced version into the context
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [debouncedInnerValue] = useDebounce(textFieldValue, 100);

  // Set debounced TextField value into the context when it changes to trigger the search/filter logic
  const dispatch = useAppDispatch();
  const goto = useGoto();
  React.useEffect(() => {
    dispatch(setSearch(debouncedInnerValue));
  }, [debouncedInnerValue]);

  // Redirect to a respective page when Autocomplete value changes (aka user did make a selection)
  React.useEffect(() => {
    // No value = we do nothing
    if (autocompleteValue === null) return;

    if (typeof autocompleteValue === "string") {
      // User submitted a search value without picking an existing option = we redirect to a search page
      goto(searchPageUrl(autocompleteValue));
    } else {
      // User selected an existing option = we redirect to that option's page
      goto(projectPageUrl(autocompleteValue));
    }

    // Clear the Autocomplete value, because it is not needed anymore
    setAutocompleteValue(null);

    // Drop the focus from the search input.
    // This is a big inconvenience from the accessibility perspective and should be reworked
    // in case accessibility of the application will be in place and supported.
    // Remember to remove `tabindex` attribute from the `body` element in the `public/index.html` file.
    document.body.focus();
  }, [autocompleteValue]);

  return (
    <SearchFieldBox>
      <Autocomplete<ProjectData, false, false, true>
        // settings
        size="small"
        freeSolo
        // Turn OFF Autocomplete built-in filtering logic
        filterOptions={options => options}
        // rendering
        renderInput={params => <SearchFieldInput autocompleteParams={params} />}
        renderOption={(props, option) => <ProjectOption key={option.id} autocompleteParams={props} project={option} />}
        // options logic
        options={foundProjects}
        // [WORKAROUND] With `freeSolo=true` and `clearOnBlue=false`
        // when a user submits a value it will stay as Autocomplete input.
        // Even if we clear it, there will be a frame with the value visible.
        // So we just don't display it at all, because it will be immediately cleared anywhere.
        getOptionLabel={_ => ""}
        // input logic
        value={autocompleteValue}
        onChange={(_, newAutocompleteValue) => setAutocompleteValue(newAutocompleteValue)}
        inputValue={textFieldValue}
        onInputChange={(_, newTextFieldValue) => setTextFieldValue(newTextFieldValue)}
        // styles customization
        // TODO: rework when theme will be in dark mode
        slotProps={{clearIndicator: {sx: {color: theme.palette.common.white}}}}
      />
    </SearchFieldBox>
  );
};

const SearchFieldBox = (props: {children: React.ReactNode}) => {
  const isMobile = useMobile();
  return (
    <Box
      sx={{
        maxWidth: isMobile ? "inherit" : ConfigSearchMaxWidth,
        flexGrow: 999,
      }}
    >
      {props.children}
    </Box>
  );
};

const SearchFieldInput = (props: {autocompleteParams: AutocompleteRenderInputParams}) => {
  const theme = useTheme();
  return (
    <TextField
      label="SEARCH"
      sx={{
        backgroundColor: alpha(theme.palette.common.black, 0.15),
        "&:hover": {
          backgroundColor: alpha(theme.palette.common.black, 0.35),
        },
        "& label": {
          color: theme.palette.common.white,
          fontSize: "0.875rem",
          transform: "translate(14px, 12px) scale(1)",
        },
        "& label[data-shrink='true']": {
          transform: "translate(14px, -9px) scale(1)",
        },
        "& label.Mui-focused": {
          color: theme.palette.common.white,
          transform: "translate(14px, -9px) scale(1)",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: alpha(theme.palette.common.black, 0.25),
          },
          "&:hover fieldset": {
            borderColor: alpha(theme.palette.common.black, 0.25),
          },
          "&.Mui-focused fieldset": {
            borderColor: alpha(theme.palette.common.black, 0.25),
          },
        },
      }}
      {...props.autocompleteParams}
    />
  );
};

const ProjectOption = (props: {project: ProjectData; autocompleteParams: React.HTMLAttributes<HTMLLIElement>}) => {
  return (
    <ProjectOption_ButtonBox {...props}>
      <ProjectOption_ImageBox {...props}>
        <ProjectOption_GradientBox {...props}>
          <ProjectOption_Game {...props} />
          <ProjectOption_Song {...props} />
        </ProjectOption_GradientBox>
      </ProjectOption_ImageBox>
    </ProjectOption_ButtonBox>
  );
};

const ProjectOption_ButtonBox = (props: {children: React.ReactNode; project: ProjectData; autocompleteParams: React.HTMLAttributes<HTMLLIElement>}) => {
  const theme = useTheme();
  return (
    <Box
      {...props.autocompleteParams}
      component="li"
      sx={{
        overflow: "hidden",
        boxShadow: theme.shadows[5],

        backgroundColor: theme.palette.common.black,
        borderStyle: "solid",
        borderWidth: 0,
        borderRadius: 0,
        borderColor: alpha(theme.palette.common.white, 0.15),
        marginBottom: theme.spacing(1),
        cursor: "pointer",

        "&.MuiAutocomplete-option": {
          padding: 0,

          "&.Mui-focused": {
            paddingLeft: 1,
            backgroundColor: theme.palette.primary.main,
          },
        },
      }}
    >
      {props.children}
    </Box>
  );
};

const ProjectOption_ImageBox = (props: {children: React.ReactNode; project: ProjectData}) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundImage: `url(${projectThumbnailUrl(props.project)})`,
        backgroundPositionX: "left",
        backgroundPositionY: "top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto 100%",
      }}
    >
      {props.children}
    </Box>
  );
};

const ProjectOption_GradientBox = (props: {children: React.ReactNode; project: ProjectData}) => {
  const theme = useTheme();
  const thumbnailSize = "100px";
  const gradientAngle = "120deg";
  const gradientStart = "50px";
  const gradientEnd = "100px";
  const backgroundColor = theme.palette.common.black;
  return (
    <Stack
      sx={{
        backgroundImage: `linear-gradient(${gradientAngle}, ${alpha(backgroundColor, 0)} ${gradientStart}, ${alpha(backgroundColor, 1)} ${gradientEnd})`,
        paddingLeft: thumbnailSize,
        paddingRight: theme.spacing(2),
        paddingY: theme.spacing(1),
        transition: "background-color 100ms linear",
      }}
    >
      {props.children}
    </Stack>
  );
};

const ProjectOption_Game = (props: {project: ProjectData}) => (
  <Typography align="left" noWrap>
    {props.project.game}
  </Typography>
);

const ProjectOption_Song = (props: {project: ProjectData}) => (
  <Typography align="left" noWrap>
    {props.project.song}
  </Typography>
);
