import React from "react";
import {BrowserRouter} from "react-router-dom";

import {ThemeProvider} from "theme";
import {RadiantRouter} from "./RadiantRouter";

const PUBLIC_PATH = process.env.PUBLIC_URL || "/";

export const RadiantApp = () => (
  <React.StrictMode>
    <ThemeProvider>
      <BrowserRouter basename={PUBLIC_PATH}>
        <RadiantRouter />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
