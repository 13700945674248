import {Theme} from "@mui/material";

import {cssBaselineOverride} from "./CssBaseline";
import {buttonOverride} from "./Button";

export const componentsOverride = (theme: Theme) =>
  Object.assign({
    MuiButton: buttonOverride(theme),
    MuiCssBaseline: cssBaselineOverride(theme),
  });
