import {Route, Routes} from "react-router-dom";
import {CommonLayout} from "./components/common/CommonLayout";
import {HomePage} from "./pages/Home.page";
import {PageNotFoundPage} from "./pages/PageNotFound.page";
import HomeIcon from "@mui/icons-material/Home";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import React from "react";
import {ProjectData, ProjectTag} from "./data/ProjectData";
import {PaletterPage} from "./pages/paletter/Paletter.page";
import {ProjectPage} from "./pages/Project.page";
import {SearchPage} from "./pages/Search.page";
import {CommissionPage} from "./pages/Commission.page";
import {BuyOcarinaPage} from "./pages/BuyOcarina.page.tsx";

/** Describes all of the routed pages of the app */
export const Pages = {
  Home: "/",
  Tabs: "/tabs",
  TabsProject: "/tabs/:projectId",
  Commission: "/commission",
  BuyOcarina: "/shop",
  Discord: "/discord",
  About: "/about",
  Contact: "/contact",
  Patreon: "/patreon",
  Paletter: "/paletter",
};

/** Describes the navigation element of the website */
export type NavigationConfigType = {
  title: string;
  route: string;
  icon: React.ReactNode;
  highlight: boolean;
};

/** Describes all navigation of the app */
export const NavigationConfig: NavigationConfigType[] = [
  {title: "Home", /*        */ route: Pages.Home, /*       */ icon: <HomeIcon />, /*               */ highlight: false},
  {title: "Tabs", /*        */ route: Pages.Tabs, /*       */ icon: <QueueMusicIcon />, /*         */ highlight: false},
  {title: "Commission", /*  */ route: Pages.Commission, /* */ icon: <PlaylistAddIcon />, /*        */ highlight: false},
  {title: "Buy Ocarina", /* */ route: Pages.BuyOcarina, /* */ icon: <ShoppingCartIcon />, /*       */ highlight: false},
  {title: "Discord", /*     */ route: Pages.Discord, /*    */ icon: <SportsEsportsIcon />, /*      */ highlight: false},
  {title: "About", /*       */ route: Pages.About, /*      */ icon: <HelpOutlineIcon />, /*        */ highlight: false},
  {title: "Patreon", /*     */ route: Pages.Patreon, /*    */ icon: <VolunteerActivismIcon />, /*  */ highlight: true},
];

/** Returns the url for the given project */
export const projectPageUrl = (project: ProjectData) => Pages.Tabs + "/" + project.id;

/** Returns the url for the given tag */
export const tagPageUrl = (tag: ProjectTag) => Pages.Tabs + "?tag=" + tag.name;

/** Returns the url for the given search query */
export const searchPageUrl = (search: string) => Pages.Tabs + "?search=" + encodeURIComponent(search);

/**
 * Decides which page to show based on current url
 */
export const RadiantRouter = () => (
  <Routes>
    <Route element={<CommonLayout />}>
      <Route path={Pages.Home} element={<HomePage />} />
      <Route path={Pages.TabsProject} element={<ProjectPage />} />
      <Route path={Pages.Tabs} element={<SearchPage />} />
      <Route path={Pages.Paletter} element={<PaletterPage />} />
      <Route path={Pages.Commission} element={<CommissionPage />} />
      <Route path={Pages.BuyOcarina} element={<BuyOcarinaPage />} />
      <Route path="*" element={<PageNotFoundPage />} />
    </Route>
  </Routes>
);
