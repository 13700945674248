import {ContentBox} from "../components/ContentBox";
import {Autocomplete, Button, Chip, Stack, TextField, Typography} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import React from "react";
import {filterProjects, ProjectData, ProjectTagsAlphabetical} from "../data/ProjectData";
import {ConfigPanelWidth} from "../components/common/Extensions";
import {ProjectCard} from "../components/ProjectCard";
import {useDebounce} from "use-debounce";
import {SocialFooter} from "../components/common/CommonLayout";
import {useAppDispatch} from "../components/common/Global";
import {setPageTitle} from "./common/NavigationState";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";

const ConfigTitleWidth = "123px";

export const SearchPage = () => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [nameFilter, setNameFilter] = React.useState<string>(searchParams.get("search") || "");
  const [tagsFilter, setTagsFilter] = React.useState<string[]>(searchParams.getAll("tag"));
  const [results, setResults] = React.useState<ProjectData[]>([]);
  const [debouncedNameFilter] = useDebounce(nameFilter, 250);

  React.useEffect(() => {
    setNameFilter(searchParams.get("search") || "");
    setTagsFilter(searchParams.getAll("tag"));
  }, [searchParams]);

  React.useEffect(() => {
    if (nameFilter.trim().length > 0) {
      searchParams.set("search", nameFilter);
    } else {
      searchParams.delete("search");
    }
    searchParams.delete("tag");
    tagsFilter.forEach(tag => searchParams.append("tag", tag));
    setSearchParams(searchParams, {replace: true});

    var title = "Tabs";
    if (nameFilter.trim().length > 0) title = title + " - " + nameFilter;
    if (tagsFilter.length > 0) title = title + " (" + tagsFilter.join(", ") + ")";
    dispatch(setPageTitle(title));
  }, [debouncedNameFilter, tagsFilter]);

  React.useEffect(() => {
    setResults(filterProjects(debouncedNameFilter, tagsFilter));
  }, [debouncedNameFilter, tagsFilter]);

  return (
    <>
      <ContentBox unlockHeight image="vynil">
        <Stack
          spacing={2}
          alignItems="left"
          sx={{
            maxWidth: ConfigPanelWidth,
            width: ConfigPanelWidth,
          }}
        >
          <SearchField value={nameFilter} setValue={setNameFilter} />
          <TagsAutocomplete value={tagsFilter} setValue={setTagsFilter} />
          <DifficultySelector tags={tagsFilter} setTags={setTagsFilter} />
          <TranspositionSelector tags={tagsFilter} setTags={setTagsFilter} />
          <ResultsCount results={results} />
        </Stack>
      </ContentBox>
      <ContentBox unlockHeight image="music">
        <ResultsView results={results} />
      </ContentBox>
      <SocialFooter />
    </>
  );
};

const SearchField = (props: {value: string; setValue: (value: string) => void}) => {
  return <TextField label="Search" placeholder="" sx={{width: "100%"}} value={props.value} onChange={event => props.setValue(event.target.value)} />;
};

const TagsAutocomplete = (props: {value: string[]; setValue: (value: string[]) => void}) => {
  const allTags = ProjectTagsAlphabetical.map(tag => tag.name);
  const initialTags = allTags.filter(tag => props.value.includes(tag));
  return <Autocomplete multiple id="tags" options={allTags} defaultValue={initialTags} renderInput={params => <TextField {...params} label="Tags Filter" placeholder="" />} sx={{width: "100%"}} value={props.value} onChange={(event, value) => props.setValue(value)} />;
};

const DifficultySelector = (props: {tags: string[]; setTags: (value: string[]) => void}) => {
  const selectDifficulty = (difficulty: string) => {
    const tag = "difficulty-" + difficulty;
    const toggling = props.tags.includes(tag);
    const cleared = props.tags.filter(tag => !tag.startsWith("difficulty-"));
    const appended = toggling ? cleared : [...cleared, tag];
    props.setTags(appended);
  };
  const selectVariant = (difficulty: string) => {
    const tag = "difficulty-" + difficulty;
    return props.tags.includes(tag) ? "white" : "contained";
  };
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography sx={{minWidth: ConfigTitleWidth}}>Difficulty:</Typography>
      <Button variant={selectVariant("easy")} onClick={() => selectDifficulty("easy")}>
        Easy
      </Button>
      <Button variant={selectVariant("medium")} onClick={() => selectDifficulty("medium")}>
        Medium
      </Button>
      <Button variant={selectVariant("hard")} onClick={() => selectDifficulty("hard")}>
        Hard
      </Button>
    </Stack>
  );
};

const TranspositionSelector = (props: {tags: string[]; setTags: (value: string[]) => void}) => {
  const selectTransposition = (transposition: boolean) => {
    const tag = transposition ? "transposed" : "no-transposition";
    const toggling = props.tags.includes(tag);
    const cleared = props.tags.filter(tag => !tag.includes("transpos"));
    const appended = toggling ? cleared : [...cleared, tag];
    props.setTags(appended);
  };
  const selectVariant = (transposition: boolean) => {
    const tag = transposition ? "transposed" : "no-transposition";
    return props.tags.includes(tag) ? "white" : "contained";
  };
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography sx={{minWidth: ConfigTitleWidth}}>Music Scale:</Typography>
      <Button variant={selectVariant(false)} onClick={() => selectTransposition(false)}>
        Original
      </Button>
      <Button variant={selectVariant(true)} onClick={() => selectTransposition(true)}>
        Transposed
      </Button>
    </Stack>
  );
};

const ResultsCount = (props: {results: ProjectData[]}) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Typography sx={{minWidth: ConfigTitleWidth}}>Songs Found:</Typography>
    <Chip label={props.results.length} sx={{borderRadius: 2, paddingX: 1}} />
  </Stack>
);

const ResultsView = (props: {results: ProjectData[]}) => {
  const [showAll, setShowAll] = React.useState(false);
  const viewedCount = 9;
  const leftOverCount = React.useMemo(() => Math.max(props.results.length - viewedCount, 0), [props.results]);
  const slicedResults = React.useMemo(() => (showAll ? props.results : props.results.slice(0, viewedCount)), [showAll, props.results]);

  React.useEffect(() => setShowAll(false), [props.results]);

  return (
    <Stack spacing={4} overflow="hidden" alignItems="center" sx={{flexGrow: 0}}>
      {slicedResults.length > 0 && (
        <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap" justifyContent="center" sx={{maxWidth: "100%"}}>
          {slicedResults.map(project => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </Stack>
      )}
      {!showAll && leftOverCount > 0 && (
        <Button variant="contained" size="large" onClick={() => setShowAll(true)} startIcon={<LibraryMusicIcon />}>
          Show {leftOverCount} more
        </Button>
      )}
      {slicedResults.length === 0 && <Typography>No songs found :(</Typography>}
    </Stack>
  );
};
