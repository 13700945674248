import {ProjectData, ProjectTag, searchProjects, searchTags} from "../../data/ProjectData";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../components/common/Global";

export interface SearchState {
  /** The current search string */
  search: string;
  /** The list of projects that match the search */
  foundProjects: ProjectData[];
  /** The list of tags that match the search */
  foundTags: ProjectTag[];
}

const initialState: SearchState = {
  search: "",
  foundProjects: [],
  foundTags: [],
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    /** Changes the searched string */
    setSearch: (state, action: PayloadAction<string>) => {
      const projects = searchProjects(action.payload)
        .sort((a, b) => b.releaseTimestamp - a.releaseTimestamp)
        .slice(0, 30);
      const tags = searchTags(action.payload);
      return {
        ...state,
        search: action.payload,
        foundProjects: projects,
        foundTags: tags,
      };
    },
  },
});

export const {setSearch} = searchSlice.actions;

export const selectSearch = (state: RootState) => state.search.search;
export const selectFoundProjects = (state: RootState) => state.search.foundProjects;
export const selectFoundTags = (state: RootState) => state.search.foundTags;

export const searchReducer = searchSlice.reducer;
