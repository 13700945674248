import {SvgIcon, SvgIconProps} from "@mui/material";

/** Main logo of the OCWALK app */
export const Logo = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 45.155556 38.362518">
    <g transform="translate(-40.862563,-31.579196)">
      <path d="m 62.535249,69.530926 c -0.892863,-0.444932 -1.320941,-1.036322 -2.564401,-3.542716 -0.808051,-1.628753 -1.102256,-2.090823 -1.637876,-2.572388 -1.16288,-1.045529 -2.694455,-1.290524 -6.039654,-0.966118 -2.662918,0.258242 -3.910979,0.262222 -5.533407,0.01767 -5.04918,-0.761129 -6.976269,-3.867349 -5.164901,-8.325151 1.259974,-3.100819 3.71786,-5.64118 9.04975,-9.353424 6.267584,-4.363705 10.957843,-6.705646 19.435096,-9.704345 8.748613,-3.094689 9.904293,-3.424247 12.059454,-3.438955 1.103614,-0.0075 1.422727,0.03993 2.006926,0.298475 1.352481,0.598561 2.02314,1.890015 1.744499,3.359288 -0.415073,2.188669 -1.859985,4.401766 -5.651536,8.656153 -5.886923,6.605549 -9.009678,11.106334 -10.348087,14.91456 -0.507601,1.444298 -0.636474,2.192304 -0.810361,4.703523 -0.07746,1.118712 -0.229175,2.454127 -0.337136,2.967586 -0.370171,1.760506 -1.322607,2.727338 -3.080624,3.127193 -1.368373,0.311227 -2.304449,0.268933 -3.127742,-0.141331 z" id="path896" />
    </g>
  </SvgIcon>
);
