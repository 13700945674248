import {SocialFooter} from "../components/common/CommonLayout";
import {ContentBox} from "../components/ContentBox";
import {TextPanel} from "../components/TextPanel";
import {ConfigTextShadow, Media_MobileAny, Media_Tablet_Plus} from "../components/common/Extensions";
import React from "react";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import {Box, Link, Stack, Typography} from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import LinkIcon from "@mui/icons-material/Link";
import {useAppDispatch} from "../components/common/Global";
import {setPageTitle} from "./common/NavigationState";
import {commissionProjects} from "../data/ProjectData";
import {ProjectCard} from "../components/ProjectCard";

export const CommissionPage = () => {
  const dispatch = useAppDispatch();
  dispatch(setPageTitle("Commission"));

  return (
    <>
      <GeneralDescriptionPanel />
      <WhatYouGet />
      <HowToCommission />
      <PastCommissionsPanel />
      <SocialFooter />
    </>
  );
};

const GeneralDescriptionPanel = () => (
  <ContentBox image="music">
    <TextPanel
      flavor="Ocarina journey continues"
      title="Commission a Tutorial"
      description={
        <>
          Sometimes, you have a song you're inspired to play, yet struggle to find an ocarina arrangement. <br />
          <Media_Tablet_Plus>
            That unfortunately happens quite often, and to lose that spark of inspiration is demoralizing. <br />
          </Media_Tablet_Plus>
          If you're interested, you can commission us to create a custom arrangement, and we'll work on it ASAP!
        </>
      }
      align="right"
    />
  </ContentBox>
);

const WhatYouGet = () => (
  <ContentBox image="vynil">
    <TextPanel
      flavor="Your very own tutorial"
      title="What You Get"
      description={
        <>
          <TextIcon icon={<MusicNoteIcon />} /> Song arranged for ocarina in tab format
          <br />
          <TextIcon icon={<FindInPageIcon />} /> Tabs available on OCWALK website
          <br />
          <TextIcon icon={<YouTubeIcon />} /> Video tutorial on OCWALK channel
          <br />
          <TextIcon icon={<CalendarMonthIcon />} /> Arrangement available within a week
          <br />
          <TextIcon icon={<LinkIcon />} /> Early access to the video (at least one day prior to release)
          <br />
        </>
      }
      align="left"
      primaryButtonTitle="Tabs Example"
      primaryButtonUrl="/tabs/up-married-life"
      secondaryButtonTitle="Video Example"
      secondaryButtonUrl="https://youtu.be/VFlQIY0vlgs"
    />
  </ContentBox>
);

const TextIcon = (props: {icon: React.ReactNode}) => (
  <>
    <Media_MobileAny>-</Media_MobileAny>
    <Media_Tablet_Plus>
      <Box sx={{verticalAlign: "-5px", display: "inline-block", paddingRight: 1}}>{props.icon}</Box>
    </Media_Tablet_Plus>
  </>
);

const HowToCommission = () => (
  <ContentBox image="generic">
    <TextPanel
      flavor="Reach out to us!"
      title="How to Commission"
      description={
        <>
          To commission a song, please email us: <Link href="mailto:owispyo@gmail.com">owispyo@gmail.com</Link>
          <br />
          The cost is $50, payable via PayPal.
          <br />
          <Media_Tablet_Plus>
            We will make sure your song is suitable for ocarina and then request an upfront payment.
            <br />
          </Media_Tablet_Plus>
          Throughout the process, we'll stay in touch to ensure you're happy with the arrangement!
        </>
      }
      align="left"
      primaryButtonTitle="Send Email"
      primaryButtonUrl="mailto:owispyo@gmail.com"
    />
  </ContentBox>
);

const PastCommissionsPanel = () => (
  <ContentBox unlockHeight image="ocarinas">
    <Stack spacing={3} overflow="hidden">
      <PastCommissionsPanel_Title />
      <PastCommissionsPanel_Projects />
    </Stack>
  </ContentBox>
);

const PastCommissionsPanel_Title = () => (
  <Typography align="center" variant="h1" textTransform="uppercase" overflow="hidden" sx={{textShadow: ConfigTextShadow}}>
    Our Past Commissions
  </Typography>
);

const PastCommissionsPanel_Projects = () => (
  <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap" justifyContent="center">
    {commissionProjects().map(project => (
      <ProjectCard key={project.id} project={project} />
    ))}
  </Stack>
);
