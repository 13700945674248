import {PaletteOptions} from "@mui/material";

export const palette: PaletteOptions = {
  mode: "dark",
  common: {
    white: "#ebede9",
    black: "#202e37",
  },
  background: {
    default: "#202e37",
    paper: "#202e37",
  },
  primary: {
    main: "#468232",
  },
  text: {
    primary: "#ebede9",
  },
} as const;
