import {configureStore} from "@reduxjs/toolkit";
import {paletterReducer} from "../../pages/paletter/PaletterState";
import {Provider, TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import React from "react";
import {navigationReducer} from "../../pages/common/NavigationState";
import {searchReducer} from "../../pages/common/SearchState";
import {tabsReducer} from "../../pages/common/TabsState";

const store = configureStore({
  reducer: {
    paletter: paletterReducer,
    navigation: navigationReducer,
    search: searchReducer,
    tabs: tabsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const ReduxProvider = (props: {children: React.ReactNode}) => (
  <Provider store={store}>
    <ReduxCustomHooks>{props.children}</ReduxCustomHooks>
  </Provider>
);

const ReduxCustomHooks = (props: {children: React.ReactNode}) => {
  return <>{props.children}</>;
};
