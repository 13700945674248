import {createSlice} from "@reduxjs/toolkit";
import React from "react";
import {RootState} from "../../components/common/Global";

export interface TabsState {
  fontSizeMultiplier: number;
  shouldDisplayNotes: boolean,
}

const initialState: TabsState = {
  fontSizeMultiplier: 1.0,
  shouldDisplayNotes: false,
};

const ConfigFontSizeMultiplierMax = 2.0;
const ConfigFontSizeMultiplierMin = 0.5;
const ConfigFontSizeMultiplierStep = 1.1;

export const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    /** Increases the tabs font size */
    increaseFontSize: state => {
      return {
        ...state,
        fontSizeMultiplier: Math.min(state.fontSizeMultiplier * ConfigFontSizeMultiplierStep, ConfigFontSizeMultiplierMax),
      };
    },
    /** Decreases the tabs font size */
    decreaseFontSize: state => {
      return {
        ...state,
        fontSizeMultiplier: Math.max(state.fontSizeMultiplier / ConfigFontSizeMultiplierStep, ConfigFontSizeMultiplierMin),
      };
    },
    /** Resets the tabs font size back to default */
    resetFontSize: state => {
      return {
        ...state,
        fontSizeMultiplier: 1.0,
      };
    },
    /** Switches to displaying notes */
    displayNotes: state => {
      return {
        ...state,
        shouldDisplayNotes: true,
      };
    },
    /** Switches to displaying tabs */
    displayTabs: state => {
      return {
        ...state,
        shouldDisplayNotes: false,
      };
    },
  },
});

export const {increaseFontSize, decreaseFontSize, resetFontSize, displayNotes, displayTabs} = tabsSlice.actions;

export const selectFontSizeMultiplier = (state: RootState) => state.tabs.fontSizeMultiplier;
export const selectDisplayNotes = (state: RootState) => state.tabs.shouldDisplayNotes;

export const tabsReducer = tabsSlice.reducer;
