import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import React from "react";
import {RootState, useAppDispatch} from "../../components/common/Global";
import {useNavigate} from "react-router-dom";

export interface NavigationState {
  /** The current page title */
  currentTitle: string;
  /** True when navigation drawer is open */
  drawerOpen: boolean;
}

const initialState: NavigationState = {
  currentTitle: "",
  drawerOpen: false,
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    /** Sets the state of the navigation drawer */
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        drawerOpen: action.payload,
      };
    },
    /** Changes the title of the page */
    setPageTitle: (state, action: PayloadAction<string>) => {
      const title = action.payload.length > 0 ? `OCWALK - ${action.payload}` : "OCWALK";
      document.title = title;
      return {
        ...state,
        currentTitle: title,
      };
    },
  },
});

export const {setDrawerOpen, setPageTitle} = navigationSlice.actions;

export const selectCurrentTitle = (state: RootState) => state.navigation.currentTitle;
export const selectDrawerOpen = (state: RootState) => state.navigation.drawerOpen;

export const navigationReducer = navigationSlice.reducer;

export const useGoto = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (route: string) => {
    navigate(route);
    window.scrollTo(0, 0);
    dispatch(setDrawerOpen(false));
  };
};
