import {Link, Outlet} from "react-router-dom";
import {AppBar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar, Typography, useTheme} from "@mui/material";
import React from "react";
import {Filler, Media_DesktopLarge_Plus, Media_DesktopMedium_Minus, Media_Tablet_Plus, Spacer, useMobile} from "./Extensions";
import MenuIcon from "@mui/icons-material/Menu";
import {NavigationConfig, NavigationConfigType, Pages} from "../../RadiantRouter";
import {Logo} from "./Logo";
import {ContentBox} from "../ContentBox";
import YouTubeIcon from "@mui/icons-material/YouTube";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import {SearchField} from "./SearchField";
import {ReduxProvider, useAppDispatch, useAppSelector} from "./Global";
import {selectDrawerOpen, setDrawerOpen, useGoto} from "../../pages/common/NavigationState";

/** Navigation buttons at the top of the header for the desktop devices */
const WideNavigation = () => (
  <Media_DesktopLarge_Plus>
    <Stack direction="row" spacing={0}>
      {NavigationConfig.map(config => (
        <NavigationButton key={config.route} config={config} />
      ))}
    </Stack>
  </Media_DesktopLarge_Plus>
);

const NavigationButton = (props: {config: NavigationConfigType}) => {
  const theme = useTheme();
  const goto = useGoto();
  const click = (event: React.UIEvent) => {
    event.preventDefault();
    goto(props.config.route);
  };
  return (
    <Button
      component={Link}
      to={props.config.route}
      variant={props.config.highlight ? "white" : "transparent"}
      color="inherit"
      sx={{
        whiteSpace: "nowrap",
        paddingX: theme.spacing(2),
        marginLeft: props.config.highlight ? theme.spacing(1) : 0,
      }}
      onClick={click}
    >
      {props.config.title}
    </Button>
  );
};

/** Burger navigation button for the mobile that simply opens the drawer */
const NarrowNavigation = () => {
  const dispatch = useAppDispatch();
  const openDrawer = () => dispatch(setDrawerOpen(true));
  return (
    <Media_DesktopMedium_Minus>
      <IconButton edge="start" color="inherit" onClick={openDrawer}>
        <MenuIcon />
      </IconButton>
    </Media_DesktopMedium_Minus>
  );
};

/** Drawer with navigation for mobile devices */
const NarrowScreenDrawer = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const drawerOpen = useAppSelector(selectDrawerOpen);
  const closeDrawer = () => dispatch(setDrawerOpen(false));
  return (
    <Media_DesktopMedium_Minus>
      <Drawer anchor="right" variant="temporary" open={drawerOpen} onClose={closeDrawer}>
        <Stack direction="column" spacing={1} sx={{flexGrow: 1, backgroundColor: theme.palette.background.default, padding: theme.spacing(2)}}>
          <Stack direction="row" spacing={2} paddingX={2} paddingY={1}>
            <Logo sx={{height: 32, width: 32}} />
            <Typography variant="h6">OCWALK</Typography>
          </Stack>
          <Divider />
          <List>
            {NavigationConfig.map(config => (
              <NavigationListItem key={config.route} config={config} />
            ))}
          </List>
        </Stack>
      </Drawer>
    </Media_DesktopMedium_Minus>
  );
};

const NavigationListItem = (props: {config: NavigationConfigType}) => {
  const goto = useGoto();
  const click = () => goto(props.config.route);
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={click}>
        <ListItemIcon>{props.config.icon}</ListItemIcon>
        <ListItemText primary={props.config.title} />
      </ListItemButton>
    </ListItem>
  );
};

/** Header layout */
const Header = () => {
  const theme = useTheme();
  const isMobile = useMobile();
  return (
    <AppBar position="sticky" sx={{backgroundColor: theme.palette.primary.main}}>
      <Toolbar
        color="primary"
        disableGutters={true}
        sx={{
          paddingX: theme.spacing(isMobile ? 1 : 4),
          maxWidth: "100%",
        }}
      >
        <Stack direction="row" spacing={isMobile ? 1 : 2} sx={{flexGrow: 1}}>
          <LogoButton />
          <Media_Tablet_Plus>
            <Filler />
          </Media_Tablet_Plus>
          <SearchField />
          <WideNavigation />
          <NarrowNavigation />
        </Stack>
        <NarrowScreenDrawer />
      </Toolbar>
    </AppBar>
  );
};

const LogoButton = () => {
  const goto = useGoto();
  const click = () => goto(Pages.Home);
  return (
    <Button variant="transparent" color="inherit" onClick={click}>
      <Stack direction="row" spacing={1}>
        <Logo sx={{height: 32, width: 32}} />
        <Media_Tablet_Plus>
          <Typography variant="h6">OCWALK</Typography>
        </Media_Tablet_Plus>
      </Stack>
    </Button>
  );
};

export type SocialFooterProps = {
  color?: "black" | "white";
};

export const SocialFooter = (props: SocialFooterProps) => {
  const theme = useTheme();
  const isMobile = useMobile();
  const colors = React.useMemo(() => {
    if (props.color === "white") {
      return {
        background: "white",
        text: theme.palette.common.black,
      };
    } else {
      return {
        background: undefined,
        text: theme.palette.common.white,
      };
    }
  }, [props.color]);
  return (
    <ContentBox unlockHeight backgroundColor={colors.background}>
      <Stack spacing={2}>
        <SocialFooterTitle color={colors.text} />
        <Stack direction="row" spacing={isMobile ? 1 : 2}>
          <SocialFooter_Button title="Youtube" url="https://www.youtube.com/@OcarinaWalk" icon={<YouTubeIcon />} />
          <SocialFooter_Button title="Patreon" url="https://www.patreon.com/wispy" icon={<VolunteerActivismIcon />} />
          <SocialFooter_Button title="Discord" url="https://discord.com/invite/QX7C3YsSnF" icon={<SportsEsportsIcon />} />
        </Stack>
      </Stack>
    </ContentBox>
  );
};

const SocialFooterTitle = (props: {color: string}) => (
  <Typography align="center" sx={{color: props.color}}>
    You can also find us here:
  </Typography>
);

const SocialFooter_Button = (props: {title: string; url: string; icon: React.ReactNode}) => (
  <Button variant="contained" component="a" href={props.url} startIcon={props.icon} target="_blank">
    {props.title}
  </Button>
);

/** Generic spacer for page content */
const CommonLayoutContentSpacing = (props: {children: React.ReactNode}) => (
  <Stack alignItems="center" spacing={1}>
    <Spacer size={0} />
    {props.children}
  </Stack>
);

const PrintPreventer = (props: {children: React.ReactNode}) => {
  const theme = useTheme();
  return (
    <>
      <Box sx={{"@media print": {display: "none"}}}>{props.children}</Box>
      <Box sx={{display: "none", "@media print": {display: "block"}}}>
        <Typography sx={{color: theme.palette.common.black}}>Please use "PRINT TABS" button instead.</Typography>
      </Box>
    </>
  );
};

/** Layout of all pages where outlet is the actual body of the page */
export const CommonLayout = () => {
  return (
    <ReduxProvider>
      <PrintPreventer>
        <Stack sx={{overflowX: "hidden"}}>
          <Header />
          <CommonLayoutContentSpacing>
            <Outlet />
          </CommonLayoutContentSpacing>
        </Stack>
      </PrintPreventer>
    </ReduxProvider>
  );
};
