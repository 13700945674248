import {OverrideFunction} from "./types";

export const cssBaselineOverride: OverrideFunction<"MuiCssBaseline"> = () => ({
  styleOverrides: {
    html: [
      {
        "@font-face": {
          fontFamily: "Ocarina12t",
          src: "url('/fonts/ocarina.ttf') format('truetype')",
        },
      },
      {
        "@font-face": {
          fontFamily: "Notes",
          src: "url('/fonts/notes.ttf') format('truetype')",
        },
      },
    ],

    "html, body, #root": {
      height: "100%",
      maxHeight: "100%",
      minWidth: `360px !important`,
    },

    body: {
      overflowY: "scroll",
    },

    "#root": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },

    "*:focus": {
      outline: "none",
    },
  },
});
