export type ColorHEX = string;
export type ColorHSL = {
  hue: number;
  saturation: number;
  luminosity: number;
};
export type ShiftHSL = {
  hueShift: number;
  saturationShift: number;
  luminosityShift: number;
};

export const EmptyColorHsl: ColorHSL = {hue: 0, saturation: 0, luminosity: 0};
export const EmptyShiftHsl: ShiftHSL = {hueShift: 0, saturationShift: 0, luminosityShift: 0};

/** Converts HSL color into HEX string */
export const HSL2HEX = (color: ColorHSL): ColorHEX => {
  const hue = color.hue / 360;
  const saturation = color.saturation / 100;
  const luminosity = color.luminosity / 100;
  let red, green, blue;
  if (saturation === 0) {
    red = green = blue = luminosity; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = luminosity < 0.5 ? luminosity * (1 + saturation) : luminosity + saturation - luminosity * saturation;
    const p = 2 * luminosity - q;
    red = hue2rgb(p, q, hue + 1 / 3);
    green = hue2rgb(p, q, hue);
    blue = hue2rgb(p, q, hue - 1 / 3);
  }
  const toHex = (x: number) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
};

/** Converts HEX string into HSL color */
export const HEX2HSL = (hex: ColorHEX): ColorHSL | undefined => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) return undefined;

  const rHex = parseInt(result[1], 16);
  const gHex = parseInt(result[2], 16);
  const bHex = parseInt(result[3], 16);

  const red = rHex / 255;
  const green = gHex / 255;
  const blue = bHex / 255;

  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);

  let hue = (max + min) / 2;
  let saturation = hue;
  let luminosity = hue;

  if (max === min) {
    // Achromatic
    return {hue: 0, saturation: 0, luminosity};
  }

  const d = max - min;
  saturation = luminosity > 0.5 ? d / (2 - max - min) : d / (max + min);
  switch (max) {
    case red:
      hue = (green - blue) / d + (green < blue ? 6 : 0);
      break;
    case green:
      hue = (blue - red) / d + 2;
      break;
    case blue:
      hue = (red - green) / d + 4;
      break;
  }
  hue /= 6;

  saturation = saturation * 100;
  saturation = Math.round(saturation);
  luminosity = luminosity * 100;
  luminosity = Math.round(luminosity);
  hue = Math.round(360 * hue);

  return {hue, saturation, luminosity};
};

export const performShiftHSL = (color: ColorHSL, shift: ShiftHSL): ColorHSL => {
  const hue = normalizeHue(color.hue + shift.hueShift);
  const saturation = normalizeSL(color.saturation + shift.saturationShift);
  const luminosity = normalizeSL(color.luminosity + shift.luminosityShift);
  return {hue, saturation, luminosity};
};

export const performComplexShift = (base: ColorHSL, offset: number, dark: ColorHSL, light: ColorHSL, linear: ShiftHSL, exponential: ShiftHSL): ColorHSL => {
  if (offset === 0) return base;

  let targetHue;
  if (offset < 0) {
    targetHue = dark.hue;
  } else {
    targetHue = light.hue;
  }
  let diff = targetHue - base.hue;
  if (diff < -180) diff = diff + 360;
  if (diff > 180) diff = diff - 360;
  const dist = Math.abs(diff);

  const hueExponential = exponential.hueShift * offset * (exponential.hueShift * offset);
  let hueShift = Math.abs(linear.hueShift * offset) + hueExponential;
  if (hueShift > dist) hueShift = dist;
  const hue = normalizeHue(base.hue + hueShift * Math.sign(diff));
  const saturation = complexShiftSL(base.saturation, linear.saturationShift, exponential.saturationShift, -offset);
  const luminosity = complexShiftSL(base.luminosity, linear.luminosityShift, exponential.luminosityShift, +offset);
  return {hue, saturation, luminosity};
};

const complexShiftSL = (base: number, linearShift: number, exponentialShift: number, offset: number): number => {
  const exponential = exponentialShift * offset * (exponentialShift * offset) * Math.sign(offset);
  const linear = linearShift * offset;
  return normalizeSL(base + linear + exponential);
};

export const normalizeHue = (hue: number): number => {
  while (hue < 0) hue = hue + 360;
  while (hue > 360) hue = hue - 360;
  return hue;
};

export const normalizeSL = (value: number): number => {
  if (value < 0) value = 0;
  if (value > 100) value = 100;
  return value;
};
