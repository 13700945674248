import {SocialFooter} from "../components/common/CommonLayout";
import {ConfigOcarinaCardHeight, ConfigOcarinaCardWidth, DesktopBr, Filler, Media_MobileAny, Media_Tablet_Plus, Spacer, useMobile} from "../components/common/Extensions";
import React from "react";
import {alpha, Box, Button, Card, Stack, Typography, useTheme} from "@mui/material";
import {useAppDispatch} from "../components/common/Global";
import {setPageTitle} from "./common/NavigationState";
import {ContentBox} from "../components/ContentBox";
import {TextPanel} from "../components/TextPanel";

export const BuyOcarinaPage = () => {
  const dispatch = useAppDispatch();
  dispatch(setPageTitle("Buy Ocarina"));

  return (
    <>
      <RecommendationsPanel />
      <OcarinaPanel />
      <SocialFooter />
    </>
  );
};

const OcarinaPanel = () => (
  <ContentBox unlockHeight image="ocarinas">
    <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap" justifyContent="center">
      <OcarinaCard
        ocarina={{
          name: "Concerto Alto C",
          image: "/ocarinas/shop-1.png",
          description: ["Standard 12-hole ocarina, tuned in C, with range of A4 - F6.", "Most played ocarina on our channel! Features a long nose, which makes it easier to support ocarina when playing high notes.", "Comes with a soft bag, and no strap."],
          linkName: "Shop at Songbird Ocarina",
          linkUrl: "https://www.songbirdocarina.com/products/ocarina-alto-c-in-strawfire?rfsn=6261743.936cec&utm_campaign=6261743.936cec&utm_medium=affiliate&utm_source=refersion",
          discount: true,
        }}
      />
      <OcarinaCard
        ocarina={{
          name: "Concerto Bass C",
          image: "/ocarinas/shop-2.png",
          description: ["Low pitch 12-hole ocarina, tuned in C, with range of A3 - F5.", "This ocarina is featured in all of our bass videos. Best for songs with long low notes. Requires more breath compared to Alto ocarinas.", "Comes with a soft bag, and no strap."],
          linkName: "Shop at Songbird Ocarina",
          linkUrl: "https://www.songbirdocarina.com/products/concerto-ocarina-bass-c-in-strawfire?rfsn=6261743.936cec&utm_campaign=6261743.936cec&utm_medium=affiliate&utm_source=refersion",
          discount: true,
        }}
      />
      <OcarinaCard
        ocarina={{
          name: "Sonoro Alto C",
          image: "/ocarinas/shop-3.png",
          description: ["Standard 12-hole ocarina, tuned in C, with range of A4 - F6.", "Surprisingly lightweight and small in size. The distance between holes is smaller compared to other ocarinas. Both sub-holes are on the right side. The one on the picture is Allegro, the Sonoro has a longer nose.", "Comes with a bag, and no strap."],
          linkName: "Shop at Songbird Ocarina",
          linkUrl: "https://www.songbirdocarina.com/products/sonoro-ocarina-alto-c-in-jade-crackle?rfsn=6261743.936cec&utm_campaign=6261743.936cec&utm_medium=affiliate&utm_source=refersion",
          discount: true,
        }}
      />
      <OcarinaCard
        ocarina={{
          name: "Pastoral Alto C Green",
          image: "/ocarinas/shop-4.png",
          description: ["Standard 12-hole ocarina, tuned in C, with range of A4 - F6.", "The design is simply incredible! Available in multiple colors (blue, red, green). This ocarina demands less air pressure, which makes it very pleasing to play.", "Comes with a bag, and no strap."],
          linkName: "Shop at Songbird Ocarina",
          linkUrl: "https://www.songbirdocarina.com/products/pastoral-alto-c-in-green?rfsn=6261743.936cec&utm_campaign=6261743.936cec&utm_medium=affiliate&utm_source=refersion",
          discount: true,
        }}
      />
    </Stack>
  </ContentBox>
);

type OcarinaData = {
  name: string;
  image: string;
  description: string[];
  linkName: string;
  linkUrl: string;
  discount: boolean;
};

/** A centered box with optional background that keeps content an expected width */
const OcarinaCard = (props: {ocarina: OcarinaData}) => (
  <Ocarina_ClickableBox {...props}>
    <Ocarina_Picture {...props} />
    <Ocarina_TextBox {...props}>
      <Ocarina_Name {...props} />
      <Ocarina_Description {...props} />
      <Filler />
      <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1} alignItems="center">
        <Ocarina_Discount {...props} />
        <Filler />
        <Ocarina_ShopButton {...props} />
      </Stack>
    </Ocarina_TextBox>
  </Ocarina_ClickableBox>
);

const Ocarina_ClickableBox = (props: {children: React.ReactNode; ocarina: OcarinaData}) => {
  const theme = useTheme();
  const isMobile = useMobile();

  return (
    <Card
      sx={{
        minWidth: isMobile ? "100%" : ConfigOcarinaCardWidth,
        maxWidth: isMobile ? "100%" : ConfigOcarinaCardWidth,
        minHeight: isMobile ? "auto" : ConfigOcarinaCardHeight,
        maxHeight: isMobile ? "auto" : ConfigOcarinaCardHeight,
        overflow: "hidden",
        display: "flex",

        boxShadow: theme.shadows[5],
        backgroundColor: "#151d28",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: alpha(theme.palette.common.white, 0.15),
        transition: "background-color 100ms linear",
      }}
    >
      <Stack sx={{width: "100%", flexGrow: 1}}>{props.children}</Stack>
    </Card>
  );
};

const Ocarina_Picture = (props: {ocarina: OcarinaData}) => {
  const isMobile = useMobile();
  const theme = useTheme();
  return (
    <Box
      component="img"
      src={"/images" + props.ocarina.image}
      sx={{
        width: isMobile ? "100%" : ConfigOcarinaCardWidth - 2,
        boxShadow: theme.shadows[5],
      }}
    />
  );
};

const Ocarina_TextBox = (props: {children: React.ReactNode; ocarina: OcarinaData}) => {
  const theme = useTheme();
  const isMobile = useMobile();
  return (
    <Stack
      spacing={2}
      sx={{
        padding: theme.spacing(isMobile ? 2 : 3),
        flexGrow: 1,
      }}
    >
      {props.children}
    </Stack>
  );
};

const Ocarina_Name = (props: {ocarina: OcarinaData}) => (
  <Typography variant="h2" noWrap sx={{overflow: "hidden"}}>
    {props.ocarina.name}
  </Typography>
);

const Ocarina_Description = (props: {ocarina: OcarinaData}) => (
  <Stack spacing={1}>
    {props.ocarina.description.map((text, index) => {
      return (
        <Typography key={index} align="left" variant="body1">
          {text}
        </Typography>
      );
    })}
  </Stack>
);

const Ocarina_Discount = (props: {ocarina: OcarinaData}) => {
  const isMobile = useMobile();
  if (props.ocarina.discount) {
    return <Typography>{isMobile ? `10% discount via code "OCWALK"` : `Get a 10% discount via code "OCWALK"`}</Typography>;
  } else {
    return null;
  }
};

const Ocarina_ShopButton = (props: {ocarina: OcarinaData}) => {
  const isMobile = useMobile();
  return (
    <Button component="a" href={props.ocarina.linkUrl} variant="contained" target="_blank" fullWidth={isMobile}>
      {props.ocarina.linkName}
    </Button>
  );
};

const RecommendationsPanel = () => (
  <ContentBox unlockHeight image="vynil">
    <TextPanel
      flavor="Pick your first ocarina"
      title="Our Advice"
      description={
        <Stack spacing={2}>
          <Typography align="left">
            Are you interested in exploring the world of ocarina and acquiring your first instrument? <DesktopBr />
            We're here to help! Here are three pieces of advice we'd like to offer beginners:
          </Typography>
          <Spacer size={1} />

          <Typography variant="h2">1. Choose a 12-hole over a 6-hole ocarina</Typography>
          <Typography align="left">While a 6-hole ocarina might look simpler, it's not actually the case. These two types of ocarinas play very differently from each other.</Typography>
          <Typography align="left">12-hole ocarinas are easier to learn because switching between notes requires moving a single finger. 6-hole ocarinas use combinations of finger placement for each note.</Typography>
          <Typography align="left">Look for ocarinas tuned in C, in a range indicated as "A4 - F6" in the product description.</Typography>
          <Spacer size={1} />

          <Typography variant="h2">2. Avoid ocarinas at amazon.com</Typography>
          <Typography align="left">While Amazon offers convenience and accessibility, there's a high risk of getting a low-quality instrument. It's disheartening when someone is introduced to the world of ocarina via a horrible instrument. Granted, these ocarinas look great on pictures and are often cheap, but they also play cheap, sound cheap, and inevitably will ruin your inspiration to play.</Typography>
          <Typography align="left">If you cannot afford a ceramic ocarina right away, "Night by Noble" plastic ocarina would be the best option. It is the only well-praised instrument available on Amazon that we know of. Otherwise...</Typography>
          <Spacer size={1} />

          <Typography variant="h2">3. Skip plastic, go for ceramic right away</Typography>
          <Typography align="left">Plastic ocarinas are a great budget option, and they may feel like a good way to try whether or not you will be interested in the hobby. But, they are actually harder to play, especially on the high notes. Compared to ceramic ocarinas, they sound windy and have a steeper breathing curve. Ceramic ocarina will sound better, and you will be more encouraged to play!</Typography>
          <Typography align="left">We encourage you, if budget allows, to go for a ceramic ocarina. They are more beginner-friendly.</Typography>
        </Stack>
      }
      align="left"
    />
  </ContentBox>
);
