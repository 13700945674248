import React from "react";
import {Button, Tooltip, useTheme} from "@mui/material";

export const RadiantIconButton = (props: {children: React.ReactNode; label: string; variant: "contained" | "outlined", onClick: () => void}) => {
  const theme = useTheme();
  const buttonSx = {["& .MuiButton-startIcon"]: {margin: 0}, minWidth: 10, maxWidth: 52, minHeight: 36, maxHeight: 36, paddingX: theme.spacing(2)};
  return (
    <Tooltip title={props.label}>
      <Button variant={props.variant} aria-label={props.label} startIcon={props.children} sx={buttonSx} onClick={props.onClick} />
    </Tooltip>
  );
};
