import {alpha, Chip, Typography, useTheme} from "@mui/material";
import React from "react";
import {findTag} from "../data/ProjectData";
import {tagPageUrl} from "../RadiantRouter";
import {useGoto} from "../pages/common/NavigationState";

/** A project tag that leads to tag search on click */
export const ProjectTagChip = (props: {tag: string}) => {
  const theme = useTheme();
  const tag = findTag(props.tag);
  const goto = useGoto();

  const icon = (
    <Typography
      sx={{
        backgroundColor: alpha("#151d28", 0.35),
        borderRadius: 16,
        fontSize: "0.8125rem",
        paddingX: theme.spacing(1),
        paddingY: theme.spacing(0.1),
      }}
    >
      {tag.count}
    </Typography>
  );

  const color = React.useMemo(() => {
    switch (props.tag) {
      case "difficulty-easy":
        return "#468232";
      case "difficulty-medium":
        return "#be772b";
      case "difficulty-hard":
        return "#a53030";
      case "transposed":
        return "#7a367b";
      default:
        return "#3c5e8b";
    }
  }, [props]);

  const link = React.useMemo(() => tagPageUrl(tag), [props]);

  const click = (event: React.UIEvent) => {
    event.preventDefault();
    goto(link);
  };

  return (
    <Chip
      clickable
      href={link}
      onClick={click}
      component="a"
      icon={icon}
      color="primary"
      label={`#${tag.name}`}
      sx={{
        boxShadow: theme.shadows[2],
        backgroundColor: color,
        "&:hover": {backgroundColor: "#819796"},
      }}
    />
  );
};
