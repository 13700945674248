import React from "react";
import {Stack, Typography} from "@mui/material";
import {ConfigPanelWidth, ConfigTextShadow, DesktopBr, Spacer, useMobile} from "../components/common/Extensions";
import {ContentBox} from "../components/ContentBox";
import {TextPanel} from "../components/TextPanel";
import {Pages, tagPageUrl} from "../RadiantRouter";
import {FeaturedProjects, findTag, latestProjects, popularTags, ProjectMap} from "../data/ProjectData";
import {ProjectCard} from "../components/ProjectCard";
import {ProjectTagChip} from "../components/ProjectTagChip";
import {SocialFooter} from "../components/common/CommonLayout";
import {useAppDispatch} from "../components/common/Global";
import {setPageTitle} from "./common/NavigationState";

export const HomePage = () => {
  const dispatch = useAppDispatch();
  dispatch(setPageTitle("Home"));

  return (
    <>
      <OcwalkProjectPanel />
      <AnimePanel />
      <VideogamePanel />
      <GhibliPanel />
      <ZeldaPanel />

      <FeaturedSongsPanel />
      <TagsPanel />
      <LatestSongsPanel />

      <SocialFooter />
    </>
  );
};

const OcwalkProjectPanel = () => (
  <ContentBox image="home-header">
    <TextPanel
      flavor="Library of tabs"
      title="OCWALK Project"
      description={
        <>
          An accessible library of ocarina tabs, created and organized with love. <DesktopBr />
          Dive into numerous arrangements of Videogame, Anime and Movie music. <DesktopBr />
          Difficulty ranging from Easy to Hard will suite learners of all levels.
        </>
      }
      align="right"
      primaryButtonTitle="Browse Tabs"
      primaryButtonUrl={Pages.Tabs}
      secondaryButtonTitle="Commission"
      secondaryButtonUrl={Pages.Commission}
    />
  </ContentBox>
);

const AnimePanel = () => (
  <ContentBox image="anime">
    <TextPanel
      flavor="Songs from favorite shows"
      title="Anime Music"
      description={
        <>
          Discover music from Ghibli movies, One Piece, JoJo's, Naruto and more. <DesktopBr />
          Ranging from happy and catchy to slow and mellow, but always nostalgic, <DesktopBr />
          songs in this category will bring much joy to you and people around.
        </>
      }
      align="left"
      primaryButtonTitle="Browse Anime Tabs"
      primaryButtonUrl={tagPageUrl(findTag("anime"))}
    />
  </ContentBox>
);

const VideogamePanel = () => (
  <ContentBox image="videogame">
    <TextPanel
      flavor="Epic themes, character tunes, tavern songs"
      title="Videogame Music"
      description={
        <>
          Fall down into the world of Undertale, visit taverns at Skyrim, toss a coin to the Witcher. <DesktopBr />
          Find main themes of many popular games, arranged and ready for your performance.
        </>
      }
      align="right"
      primaryButtonTitle="Browse Videogame Tabs"
      primaryButtonUrl={tagPageUrl(findTag("videogame"))}
    />
  </ContentBox>
);

const GhibliPanel = () => (
  <ContentBox image="ghibli">
    <TextPanel
      flavor="Imprinted in our memories"
      title="Studio Ghibli"
      description={
        <>
          Start your journey with themes from Spirited Away, walk the path of Totoro forest, <DesktopBr />
          and take a break next to Calcifer, playing an iconic Merry Go Round of Life. <DesktopBr />
          Enjoy the vastness of one of the two largest categories in OCWALK.
        </>
      }
      align="left"
      primaryButtonTitle="Browse Ghibli Tabs"
      primaryButtonUrl={tagPageUrl(findTag("ghibli"))}
    />
  </ContentBox>
);

const ZeldaPanel = () => (
  <ContentBox image="zelda">
    <TextPanel
      flavor="It's dangerous to go alone!"
      title="The Legend of Zelda"
      description={
        <>
          Pay a visit to the Great Fairy, travel through Kokiri Forest, <DesktopBr />
          and put the tormented at ease with a Song of Healing. <DesktopBr />
          Explore the largest category of music on OCWALK.
        </>
      }
      align="right"
      primaryButtonTitle="Browse Zelda Tabs"
      primaryButtonUrl={tagPageUrl(findTag("zelda"))}
    />
  </ContentBox>
);

const FeaturedSongsPanel = () => (
  <ContentBox unlockHeight image="vynil">
    <Stack spacing={3} overflow="hidden">
      <FeaturedSongsPanel_Title />
      <FeaturedSongsPanel_Description />
      <Spacer size={0} />
      <FeaturedSongsPanel_Projects />
    </Stack>
  </ContentBox>
);

const FeaturedSongsPanel_Title = () => {
  const isMobile = useMobile();
  return (
    <Typography align="center" variant="h1" textTransform="uppercase" overflow="hidden" sx={{textShadow: ConfigTextShadow}}>
      {isMobile ? "Featured" : "Featured Songs"}
    </Typography>
  );
};

const FeaturedSongsPanel_Description = () => (
  <Typography align="center" variant="body1" sx={{textShadow: ConfigTextShadow}}>
    The world of ocarina music is vast, so let us share top picks from our tutorials. <DesktopBr />
    These compositions are nothing but joy to play on ocarina! Happy learning!
  </Typography>
);

const FeaturedSongsPanel_Projects = () => (
  <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap" justifyContent="center">
    {FeaturedProjects.map((projectId, index) => (
      <ProjectCard key={projectId} project={ProjectMap[projectId]} />
    ))}
  </Stack>
);

const TagsPanel = () => (
  <ContentBox unlockHeight image="generic">
    <Stack spacing={3} paddingY={1} maxWidth={ConfigPanelWidth}>
      <TagsPanel_Title />
      <TagsPanel_Tags />
    </Stack>
  </ContentBox>
);

const TagsPanel_Title = () => (
  <Typography align="center" variant="h1" textTransform="uppercase" overflow="hidden" sx={{textShadow: ConfigTextShadow}}>
    Popular Tags
  </Typography>
);

const TagsPanel_Tags = () => (
  <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap" justifyContent="center">
    {popularTags(10).map((tag, index) => (
      <ProjectTagChip key={tag.name} tag={tag.name} />
    ))}
  </Stack>
);

const LatestSongsPanel = () => (
  <ContentBox unlockHeight image="ocarinas">
    <Stack spacing={3} overflow="hidden">
      <LatestSongsPanel_Title />
      <LatestSongsPanel_Projects />
    </Stack>
  </ContentBox>
);

const LatestSongsPanel_Title = () => (
  <Typography align="center" variant="h1" textTransform="uppercase" overflow="hidden" sx={{textShadow: ConfigTextShadow}}>
    Latest Covers
  </Typography>
);

const LatestSongsPanel_Projects = () => (
  <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap" justifyContent="center">
    {latestProjects(9).map((project, index) => (
      <ProjectCard key={project.id} project={project} />
    ))}
  </Stack>
);
