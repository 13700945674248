import {Button, Stack, Typography} from "@mui/material";
import React from "react";
import {ConfigPanelWidth, ConfigTextShadow, Spacer, useMobile} from "./common/Extensions";
import {useGoto} from "../pages/common/NavigationState";

interface TextPanelProps {
  flavor: string;
  title: string;
  description: React.ReactNode;
  align: "right" | "left" | "center";
  primaryButtonTitle?: string;
  primaryButtonUrl?: string;
  secondaryButtonTitle?: string;
  secondaryButtonUrl?: string;
}

/** A centered box with optional background that keeps content an expected width */
export const TextPanel: React.FC<TextPanelProps> = (props: TextPanelProps) => (
  <TextPanelBox {...props}>
    <TextPanelFlavor {...props} />
    <TextPanelTitle {...props} />
    <TextPanelDescription {...props} />
    <Spacer size={1} />
    <TextPanelButtons {...props} />
  </TextPanelBox>
);

const TextPanelBox = (props: TextPanelProps & {children: React.ReactNode}) => (
  <Stack
    spacing={1}
    sx={{
      display: "flex",
      justifyContent: props.align,
      maxWidth: ConfigPanelWidth,
      width: ConfigPanelWidth,
      textShadow: ConfigTextShadow,
    }}
  >
    {props.children}
  </Stack>
);

const TextPanelFlavor = (props: TextPanelProps) => {
  const isMobile = useMobile();
  return (
    <Typography align={isMobile ? "center" : props.align} variant="h3" textTransform="uppercase">
      {props.flavor}
    </Typography>
  );
};

const TextPanelTitle = (props: TextPanelProps) => {
  const isMobile = useMobile();
  return (
    <Typography align={isMobile ? "center" : props.align} variant="h1" textTransform="uppercase">
      {props.title}
    </Typography>
  );
};

const TextPanelDescription = (props: TextPanelProps) => {
  const isMobile = useMobile();
  return (
    <Typography align={isMobile ? "center" : props.align} variant="body1">
      {props.description}
    </Typography>
  );
};

const TextPanelButtons = (props: TextPanelProps) => {
  const isMobile = useMobile();
  return (
    <Stack spacing={1} direction="row" justifyContent={isMobile ? "center" : props.align}>
      <TextPanelButton title={props.primaryButtonTitle} url={props.primaryButtonUrl} />
      <TextPanelButton title={props.secondaryButtonTitle} url={props.secondaryButtonUrl} />
    </Stack>
  );
};

const TextPanelButton = (props: {title?: string; url?: string}) => {
  if (props.title && props.url) {
    const goto = useGoto();
    const click = () => {
      if (props.url) goto(props.url);
    };
    return (
      <Button variant="contained" size="large" onClick={click}>
        {props.title}
      </Button>
    );
  } else {
    return null;
  }
};
