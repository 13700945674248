import React from "react";
import {Pages} from "../RadiantRouter";
import {ContentBox} from "../components/ContentBox";
import {TextPanel} from "../components/TextPanel";
import {DesktopBr} from "../components/common/Extensions";
import {SocialFooter} from "../components/common/CommonLayout";
import {useAppDispatch} from "../components/common/Global";
import {setPageTitle} from "./common/NavigationState";

export const PageNotFoundPage = () => {
  const dispatch = useAppDispatch();
  dispatch(setPageTitle("404"));

  return (
    <>
      <PageNotFoundPanel />
      <SocialFooter />
    </>
  );
};

const PageNotFoundPanel = () => (
  <ContentBox>
    <TextPanel
      flavor="Page not found"
      title="Sorry"
      description={
        <>
          The page you are trying to access does not appear to exist. <DesktopBr />
          If you think there is a bug on the website, please contact us.
        </>
      }
      align="center"
      primaryButtonTitle="Return Home"
      primaryButtonUrl={Pages.Home}
      secondaryButtonTitle="Contact Us"
      secondaryButtonUrl={Pages.Contact}
    />
  </ContentBox>
);
