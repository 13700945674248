import Data from "./data.json";
import {compareTwoStrings} from "string-similarity";
import dateFormat, {masks} from "dateformat";

/** Data structure for one project, loaded from data file */
export type ProjectDataRaw = {
  id: string;
  song: string;
  game: string;
  originalUrl: string;
  coverUrl: string;
  difficulty: string;
  transposition: number;
  tags: string[];
  tabs: string[];
  releaseTimestamp: number;
};

/** Data structure for one project, enhanced from raw data */
export type ProjectData = ProjectDataRaw & {
  searchString: string;
  sortedTags: string[];
};

/** A type for object that maps ids to project data */
export type ProjectMapType = {
  [key: string]: ProjectData;
};

/** Describes how many times a certain tag appears in projects */
export type ProjectTag = {
  name: string;
  count: number;
};

/** A list of all OCWALK projects, sorted by release date (ascending) */
export var ProjectList: ProjectData[] = [];
/** A map of all OCWALK projects with keys being ids */
export var ProjectMap: ProjectMapType = {};
/** A list of all project tags, sorted from most popular to the least popular */
export var ProjectTags: ProjectTag[] = [];
/** Project tags list sorted alphabetically */
export var ProjectTagsAlphabetical: ProjectTag[] = [];
/** A list of songs to display as featured */
export const FeaturedProjects: string[] = ["nier-grandma", "avatar-the-last-airbender-leaves-from-vine", "zelda-botw-kakariko-night"];

/** Initialized some project values */
{
  const dataCopy: ProjectDataRaw[] = JSON.parse(JSON.stringify(Data));
  ProjectList = dataCopy
    .sort((a, b) => a.releaseTimestamp - b.releaseTimestamp)
    .filter(value => value.releaseTimestamp < Date.now())
    .map(value => {
      value.tags.push(`difficulty-${value.difficulty}`);
      if (value.transposition === 0) value.tags.push("no-transposition");
      else value.tags.push("transposed");
      return value;
    })
    .map(project => {
      let searchString = `${project.game} ${project.song}`;
      project.tags.forEach(tag => (searchString = searchString + " " + tag));
      return {
        searchString,
        sortedTags: [],
        ...project,
      };
    });
  ProjectTags = ProjectList.flatMap(value => value.tags)
    .reduce((total: ProjectTag[], current) => {
      const tag = total.find(value => value.name === current);
      if (tag) tag.count++;
      else total.push({name: current, count: 1});
      return total;
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => b.count - a.count);
  ProjectList = ProjectList.map(project => {
    return {
      ...project,
      sortedTags: ProjectTags.filter(tag => project.tags.includes(tag.name)).map(tag => tag.name),
    };
  });
  ProjectMap = ProjectList.reduce<ProjectMapType>((total, current) => {
    total[current.id] = current;
    return total;
  }, {});
  ProjectTagsAlphabetical = [...ProjectTags].sort((a, b) => (a.name > b.name ? 1 : -1));

  verifyProjects();
}

/** Returns last projects of given size, sorted from latest to oldest */
export function latestProjects(size: number): ProjectData[] {
  return ProjectList.slice(-size).reverse();
}

/** Returns all commissions, latest first */
export function commissionProjects(): ProjectData[] {
  return ProjectList.filter(project => project.tags.includes("commission")).reverse();
}

/** Returns projects that match the given search query */
export function searchProjects(search: string): ProjectData[] {
  return searchAny(search, ProjectList, project => project.searchString);
}

/** Returns tags that match the given search query */
export function searchTags(search: string): ProjectTag[] {
  return searchAny(search, ProjectTags, tag => tag.name);
}

/** Returns project that match the name and contain given tags */
export function filterProjects(nameFilter: string, tagsFilter: string[]): ProjectData[] {
  const nameFiltered = nameFilter.length > 0 ? searchAny(nameFilter, ProjectList, project => project.searchString) : ProjectList;
  const tagFiltered = nameFiltered.filter(project => tagsFilter.every(tag => project.tags.includes(tag)));
  return tagFiltered.reverse();
}

/** Searches for any type of value using the mapper for search strings */
export function searchAny<A>(search: string, options: A[], mapper: (a: A) => string): A[] {
  const words = search
    .trim()
    .split(" ")
    .filter(word => word.length > 0)
    .map(word => word.toLowerCase());
  if (words.length == 0) {
    return [];
  }
  return options.filter(option => {
    const optionSearch = mapper(option).toLowerCase();
    for (const word of words) {
      if (!optionSearch.includes(word)) return false;
    }
    return true;
  });
}

/** Returns the most popular tags of given count, starting with the most popular */
export function popularTags(size: number): ProjectTag[] {
  return ProjectTags.slice(0, size).filter(value => value.count > 1);
}

/** Returns the project tag with the given name */
export function findTag(tag: string): ProjectTag {
  const result = ProjectTags.find(value => value.name === tag);
  if (result) return result;
  else return {name: tag, count: 1};
}

/** Returns the url of the project video thumbnail */
export function projectThumbnailUrl(project: ProjectData): string {
  return `https://img.youtube.com/vi/${projectCoverId(project)}/maxresdefault.jpg`;
}

/** Returns the youtube id of the project cover video */
export function projectCoverId(project: ProjectData): string {
  return parseYoutubeId(project.coverUrl);
}

/** Returns the youtube id of the project original video */
export function projectOriginalId(project: ProjectData): string {
  return parseYoutubeId(project.originalUrl);
}

/** Parses the id of the youtube video from the given url */
function parseYoutubeId(url: string): string {
  const pattern1 = /^https:\/\/youtu\.be\/(.+)/;
  const pattern2 = /^https:\/\/www\.youtube\.com\/watch\?v=(.+)/;
  const match1 = pattern1.exec(url);
  const match2 = pattern2.exec(url);
  let youtubeId = "";
  if (match1) {
    youtubeId = match1[1];
  } else if (match2) {
    youtubeId = match2[1];
  } else {
    console.error("failed to parse youtube url: " + url);
  }
  return youtubeId;
}

/** Looks at the project data and tries to find any errors */
function verifyProjects(): void {
  if (process.env.NODE_ENV === "development") {
    console.log("verifying projects...");

    console.log("looking at timestamps...");
    ProjectList.forEach(a => {
      ProjectList.forEach(b => {
        if (a.id !== b.id && a.releaseTimestamp === b.releaseTimestamp) {
          console.log(`  same timestamp: ${a.id} ${b.id} ${a.releaseTimestamp}`);
        }
      });
    });
    console.log("timestamps check is done!");

    console.log("looking at tags similarity...");
    var similarityResults: {a: string; b: string; s: number}[] = [];
    const similarityThreshold = 0.5;
    ProjectTags.forEach(a => {
      ProjectTags.forEach(b => {
        if (a.name !== b.name && !a.name.includes("difficulty")) {
          const similarity = compareTwoStrings(a.name, b.name);
          if (similarity > similarityThreshold) {
            similarityResults.push({a: a.name, b: b.name, s: similarity});
          }
        }
      });
    });
    similarityResults
      .sort((a, b) => b.s - a.s)
      .slice(0, 10)
      .forEach(sim => {
        console.log(`  similar tags: ${sim.s} ${sim.a} ${sim.b}`);
      });

    console.log("looking at youtube ids...");
    ProjectList.forEach(a => {
      [a.coverUrl, a.originalUrl].forEach(url => {
        if (parseYoutubeId(url) === "") {
          console.log(`  unparseable youtube id: ${a.id} ${url}`);
        }
      });
    });
    console.log("youtube id check is done!");

    console.log("checking release timestamp gaps...");
    const date = (millis: number) => {
      return dateFormat(new Date(millis), "longDate");
    };
    ProjectList.forEach((a, index) => {
      if (index + 1 < ProjectList.length) {
        const b = ProjectList[index + 1];
        const days = Math.abs(b.releaseTimestamp - a.releaseTimestamp) / 1000 / 60 / 60 / 24;
        if (days > 5) {
          console.log(`  large timestamp gap: ${days} days`);
          console.log(`    ${a.id} - ${b.id}`);
          console.log(`    ${date(a.releaseTimestamp)} - ${date(b.releaseTimestamp)}`);
        }
      }
    });
    console.log("release timestamp check is done!");

    console.log("verification done!");
    console.log("------------------------------------------------");
  }
}
