import React from "react";
import {Box, useMediaQuery, useTheme} from "@mui/material";

enum Media {
  Zero = "Zero",
  Mobile = "Mobile",
  Tablet = "Tablet",
  DesktopMedium = "DesktopMedium",
  DesktopLarge = "DesktopLarge",
  Infinite = "Infinite",
}

/** Size of the content boxes */
export const ConfigContentWidth = 1920;
/** Size of the content within panels */
export const ConfigPanelWidth = 1024;
/** The width of the project card */
export const ConfigProjectCardWidth = 500;
/** The height of the project card */
export const ConfigProjectCardHeight = 500;
/** The width of the ocarina card */
export const ConfigOcarinaCardWidth = 750;
/** The height of the ocarina card */
export const ConfigOcarinaCardHeight = 800;
/** Text shadow to contrast with the background images */
export const ConfigTextShadow = "0 0 1px transparent,0 2px 3px rgba(0,0,0,.8)";
/** The maximum width of the search bar */
export const ConfigSearchMaxWidth = 500;
/** All of the maximum sizes for different devices */
const ConfigMediaSizes: {[key in Media]: number} = {
  [Media.Zero]: 0,
  [Media.Mobile]: 760,
  [Media.Tablet]: 1024,
  [Media.DesktopMedium]: 1520,
  [Media.DesktopLarge]: 1920,
  [Media.Infinite]: 99999,
};

/** Returns true when viewing on mobile device */
export const useMobile = () => {
  return useMediaBetween(Media.Mobile, Media.Mobile);
};

/** Returns true when viewing on a Desktop Large device or larger */
export const useDesktopLargePlus = () => {
  return useMediaBetween(Media.DesktopLarge, Media.Infinite);
};

/** Returns the media query for the devices between the given ones (inclusive) */
export const useMediaBetween = (from: Media, to: Media) => {
  const fromIndex = Object.values(Media).indexOf(from);
  const beforeFromIndex = Math.max(0, fromIndex - 1);
  const beforeFrom = Object.values(Media).at(beforeFromIndex);
  // @ts-ignore
  const sizeFrom = ConfigMediaSizes[beforeFrom] + 1;
  const sizeTo = ConfigMediaSizes[to];
  const query = `(min-width: ${sizeFrom}px) and (max-width: ${sizeTo}px)`;
  return useMediaQuery(query);
};

/** Shows the children only when device size is between the given sizes, inclusive */
export const MediaBetween = (props: {children: React.ReactNode; from?: Media; to?: Media}) => {
  const fromValue = props.from === undefined ? Media.Zero : props.from;
  const toValue = props.to === undefined ? Media.Infinite : props.to;
  const isBetween = useMediaBetween(fromValue, toValue);
  return <>{isBetween ? props.children : null}</>;
};

/** Displays the children only if viewing on mobile device */
export const Media_MobileAny = (props: {children: React.ReactNode}) => <MediaBetween to={Media.Mobile}>{props.children}</MediaBetween>;

/** Displays the children only if viewing on any desktop device */
export const Media_Tablet_Plus = (props: {children: React.ReactNode}) => <MediaBetween from={Media.Tablet}>{props.children}</MediaBetween>;

/** Displays the children only if viewing on a device smaller than medium desktop */
export const Media_DesktopMedium_Minus = (props: {children: React.ReactNode}) => {
  return <MediaBetween to={Media.DesktopMedium}>{props.children}</MediaBetween>;
};

/** Displays the children only if viewing on large desktop device */
export const Media_DesktopLarge_Plus = (props: {children: React.ReactNode}) => <MediaBetween from={Media.DesktopLarge}>{props.children}</MediaBetween>;

/** Places a linebreak only for desktop devices */
export const DesktopBr = () => (
  <Media_Tablet_Plus>
    <br />
  </Media_Tablet_Plus>
);

/** Inserts a vertical space of the given theme size */
export const Spacer = (props: {size: number}) => {
  const theme = useTheme();
  return <Box sx={{minHeight: theme.spacing(props.size)}}></Box>;
};

/** Inserts a box that fills all of the leftover flex space */
export const Filler = () => {
  return <Box sx={{flexGrow: 1}} />;
};

/** Hides the content when printing the page */
export const HideOnPrint = (props: {children: React.ReactNode}) => {
  return <Box sx={{"@media print": {display: "none", visibility: "hidden"}}}>{props.children}</Box>;
};
