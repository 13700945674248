import React from "react";
import {createTheme, CssBaseline, StyledEngineProvider, ThemeProvider as MuiThemeProvider} from "@mui/material";

import {componentsOverride} from "./overrides";
import {palette} from "./palette";
import {typographyDesktop, typographyMobile} from "./fonts";
import {useMobile} from "../components/common/Extensions";

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = (props: ThemeProviderProps) => {
  const isMobile = useMobile();
  const theme = React.useMemo(() => createTheme({palette, typography: isMobile ? typographyMobile : typographyDesktop}), [isMobile]);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
