import React from "react";
import {ColorHSL, EmptyShiftHsl, normalizeHue, performComplexShift, performShiftHSL, ShiftHSL} from "../../utils/colors";
import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../components/common/Global";

export interface PaletterState {
  primary: ColorHSL;
  dark: ColorHSL;
  light: ColorHSL;
  secondary: ShiftHSL[];
  shades: number;
  linearShift: ShiftHSL;
  exponentialShift: ShiftHSL;
}

const initialState: PaletterState = {
  primary: {hue: 0, saturation: 100, luminosity: 50},
  dark: {hue: 240, saturation: 100, luminosity: 50},
  light: {hue: 53, saturation: 100, luminosity: 50},
  secondary: [],
  shades: 1,
  linearShift: EmptyShiftHsl,
  exponentialShift: EmptyShiftHsl,
};

export const paletterSlice = createSlice({
  name: "paletter",
  initialState,
  reducers: {
    setPrimaryHSL: (state, action: PayloadAction<ColorHSL>) => {
      return {
        ...state,
        primary: action.payload,
        dark: {...state.dark, saturation: action.payload.saturation, luminosity: action.payload.luminosity},
        light: {...state.light, saturation: action.payload.saturation, luminosity: action.payload.luminosity},
      };
    },
    setDarkHSL: (state, action: PayloadAction<ColorHSL>) => {
      return {
        ...state,
        dark: {...state.dark, hue: action.payload.hue},
      };
    },
    setLightHSL: (state, action: PayloadAction<ColorHSL>) => {
      return {
        ...state,
        light: {...state.light, hue: action.payload.hue},
      };
    },
    addSecondaryColor: state => {
      return {
        ...state,
        secondary: [...state.secondary, EmptyShiftHsl],
      };
    },
    removeSecondaryColor: state => {
      if (state.secondary.length === 0) return state;
      return {
        ...state,
        secondary: state.secondary.slice(0, state.secondary.length - 1),
      };
    },
    setSecondaryShift: (state, action: PayloadAction<{index: number; value: ShiftHSL}>) => {
      if (action.payload.index >= state.secondary.length || action.payload.index < 0) return state;
      return {
        ...state,
        secondary: Object.assign([], state.secondary, {[action.payload.index]: action.payload.value}),
      };
    },
    incrementShadesAmount: state => {
      return {
        ...state,
        shades: state.shades + 1,
      };
    },
    decrementShadesAmount: state => {
      if (state.shades === 1) return state;
      return {
        ...state,
        shades: state.shades - 1,
      };
    },
    setLinearShift: (state, action: PayloadAction<ShiftHSL>) => {
      return {
        ...state,
        linearShift: action.payload,
      };
    },
    setExponentialShift: (state, action: PayloadAction<ShiftHSL>) => {
      return {
        ...state,
        exponentialShift: action.payload,
      };
    },
    exportState: state => {
      const json = JSON.stringify(state);
      navigator.clipboard.writeText(json);
      return state;
    },
    importState: (state, action: PayloadAction<string>) => {
      const json: PaletterState = JSON.parse(action.payload);
      return json;
    },
  },
});

export const {setPrimaryHSL, setDarkHSL, setLightHSL, addSecondaryColor, removeSecondaryColor, setSecondaryShift, incrementShadesAmount, decrementShadesAmount, setLinearShift, setExponentialShift, exportState, importState} = paletterSlice.actions;

export const selectPrimary = (state: RootState) => state.paletter.primary;
export const selectDark = (state: RootState) => state.paletter.dark;
export const selectLight = (state: RootState) => state.paletter.light;
export const selectColorAmount = (state: RootState) => state.paletter.secondary.length + 1;
export const selectSecondaryAmount = (state: RootState) => state.paletter.secondary.length;
export const selectSecondaryShifts = (state: RootState) => state.paletter.secondary;
export const selectSecondaryShift = (index: number) => (state: RootState) => state.paletter.secondary[index];
export const selectSecondaryColor = (index: number) =>
  createSelector([selectPrimary, selectSecondaryShift(index)], (primary, secondaryShift) => {
    return {...performShiftHSL(primary, secondaryShift), hue: normalizeHue(secondaryShift.hueShift)};
  });
export const selectShadesAmount = (state: RootState) => state.paletter.shades;
export const selectLinearShift = (state: RootState) => state.paletter.linearShift;
export const selectExponentialShift = (state: RootState) => state.paletter.exponentialShift;
export const selectLinearColor = createSelector([selectPrimary, selectLinearShift], (primary, linearShift) => {
  return performShiftHSL(primary, linearShift);
});
export const selectExponentialColor = createSelector([selectPrimary, selectExponentialShift], (primary, exponentialShift) => {
  return performShiftHSL(primary, exponentialShift);
});
export const selectPaletteResult = createSelector([selectPrimary, selectDark, selectLight, selectSecondaryShifts, selectShadesAmount, selectLinearShift, selectExponentialShift], (primary, dark, light, secondaryShifts, shadesAmount, linearShift, exponentialShift) => {
  const baseColors = [primary];
  secondaryShifts.forEach(shift => {
    const shifted = performShiftHSL(primary, shift);
    baseColors.push({...shifted, hue: normalizeHue(shift.hueShift)});
  });
  const leftShift = Math.floor(shadesAmount / 2);
  return baseColors.map(baseColor => {
    let row: ColorHSL[] = [];
    for (let index = 0; index < shadesAmount; index++) {
      const offset = index - leftShift;
      const shade = performComplexShift(baseColor, offset, dark, light, linearShift, exponentialShift);
      row.push(shade);
    }
    return row;
  });
});

export const paletterReducer = paletterSlice.reducer;
