import {alpha, Box, ButtonBase, Card, Stack, Typography, useTheme} from "@mui/material";
import React from "react";
import {ConfigProjectCardWidth, Filler, useMobile} from "./common/Extensions";
import {ProjectData, projectThumbnailUrl} from "../data/ProjectData";
import moment from "moment";
import {projectPageUrl} from "../RadiantRouter";
import {useGoto} from "../pages/common/NavigationState";

/** A centered box with optional background that keeps content an expected width */
export const ProjectCard = (props: {project: ProjectData}) => (
  <Project_ClickableBox {...props}>
    <Project_TextBox {...props}>
      <Project_GameName {...props} />
      <Project_SongName {...props} />
      <Project_Meta {...props} />
    </Project_TextBox>
    <Project_Thumbnail {...props} />
  </Project_ClickableBox>
);

const Project_ClickableBox = (props: {children: React.ReactNode; project: ProjectData}) => {
  const theme = useTheme();
  const isMobile = useMobile();
  const goto = useGoto();

  const click = (event: React.UIEvent) => {
    event.preventDefault();
    goto(projectPageUrl(props.project));
  };
  const middleClick = (event: React.MouseEvent) => {
    if (event.button === 1) {
      window.open(projectPageUrl(props.project), "_blank");
    }
  };

  return (
    <Card
      sx={{
        minWidth: isMobile ? "100%" : ConfigProjectCardWidth,
        maxWidth: isMobile ? "100%" : ConfigProjectCardWidth,
        overflow: "hidden",

        boxShadow: theme.shadows[5],
        backgroundColor: "#151d28",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {backgroundColor: "#202e37"},
        transition: "background-color 100ms linear",
      }}
    >
      <ButtonBase onClick={click} onMouseUp={middleClick} sx={{width: "100%"}}>
        <Stack
          spacing={isMobile ? 2 : 3}
          sx={{
            width: "100%",
            paddingTop: theme.spacing(isMobile ? 2 : 3),
          }}
        >
          {props.children}
        </Stack>
      </ButtonBase>
    </Card>
  );
};

const Project_TextBox = (props: {children: React.ReactNode; project: ProjectData}) => {
  const theme = useTheme();
  const isMobile = useMobile();
  return (
    <Stack
      sx={{
        paddingX: theme.spacing(isMobile ? 2 : 3),
      }}
    >
      {props.children}
    </Stack>
  );
};

const Project_GameName = (props: {project: ProjectData}) => (
  <Typography align="left" textTransform="uppercase" noWrap sx={{overflow: "hidden"}}>
    {props.project.game}
  </Typography>
);

const Project_SongName = (props: {project: ProjectData}) => (
  <Typography align="left" variant="h2" noWrap>
    {props.project.song}
  </Typography>
);

const Project_Meta = (props: {project: ProjectData}) => (
  <Stack direction="row" spacing={1} sx={{textOverflow: "ellipsis"}}>
    <ProjectDifficulty {...props} />
    <Filler />
    <ProjectReleaseDate {...props} />
  </Stack>
);

const ProjectReleaseDate = (props: {project: ProjectData}) => (
  <Typography align="right" noWrap textTransform="capitalize" sx={{color: "#a8b5b2"}}>
    {moment(props.project.releaseTimestamp).fromNow()}
  </Typography>
);

const ProjectDifficulty = (props: {project: ProjectData}) => {
  const difficultyColor = React.useMemo(() => {
    switch (props.project.difficulty) {
      case "easy":
        return "#75a743";
      case "medium":
        return "#be772b";
      case "hard":
        return "#a53030";
      default:
        return "#4f8fba";
    }
  }, [props]);
  return (
    <Typography align="left" noWrap textTransform="capitalize" sx={{color: difficultyColor}}>
      {props.project.difficulty}
    </Typography>
  );
};

const Project_Thumbnail = (props: {project: ProjectData}) => {
  const isMobile = useMobile();
  return <Box component="img" src={projectThumbnailUrl(props.project)} sx={{width: isMobile ? "100%" : ConfigProjectCardWidth - 2}} />;
};
