import {TypographyOptions} from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    tabs: React.CSSProperties;
    notes: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    tabs?: React.CSSProperties;
    notes?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    tabs: true;
    notes: true;
  }
}

/**
 * H1 is used for major website headers, like section titles
 * H2 is used for minor headers, like song names
 * H3 is used to tiny flavor text accompanying H1 headers
 * BODY1 is used for all the long readable blocks of text
 */

/** Default MUI values for different typography sizes */
export const typographyDefault: TypographyOptions = {
  h1: {
    fontSize: "6rem",
    lineHeight: "1.167",
  },
  h2: {
    fontSize: "3.75rem",
    lineHeight: "1.2",
  },
  h3: {
    fontSize: "3rem",
    lineHeight: "1.167",
  },
  h4: {
    fontSize: "2.125rem",
    lineHeight: "1.235",
  },
  h5: {
    fontSize: "1.5rem",
    lineHeight: "1.334",
  },
  h6: {
    fontSize: "1.25rem",
    lineHeight: "1.6",
  },
  body1: {
    fontSize: "1rem",
    lineHeight: "1.5",
  },
  tabs: {
    fontFamily: "Ocarina12t",
    lineHeight: "1.2",
    color: "#202e37",
  },
  notes: {
    fontFamily: "Notes",
    lineHeight: "1.2",
    color: "#202e37",
  },
};

/** Desktop font settings */
export const typographyDesktop: TypographyOptions = {
  fontFamily: "Atkinson Hyperlegible, sans-serif",
  h1: {...typographyDefault.h2, fontWeight: 700, "@media print": {fontSize: "2.5rem"}},
  h2: {...typographyDefault.h4, fontWeight: 700, "@media print": {fontSize: "1.5rem"}},
  h3: typographyDefault.h5,
  h4: typographyDefault.h6,
  body1: typographyDefault.h6,
  tabs: {
    ...typographyDefault.tabs,
    fontSize: "3.8em",
    letterSpacing: "-0.19em",
  },
  notes: {
    ...typographyDefault.notes,
    fontSize: "4em",
    letterSpacing: "-0.1em",
    lineHeight: "0.8em",
  },
};

/** Mobile font settings */
export const typographyMobile: TypographyOptions = {
  fontFamily: "Atkinson Hyperlegible, sans-serif",
  h1: {...typographyDefault.h3, fontWeight: 700},
  h2: {...typographyDefault.h4, fontWeight: 700},
  h3: typographyDefault.h5,
  h4: typographyDefault.h6,
  body1: typographyDefault.h6,
  tabs: {
    ...typographyDefault.tabs,
    fontSize: "1.8em",
    letterSpacing: "-0.19em",
  },
  notes: {
    ...typographyDefault.notes,
    fontSize: "2em",
    letterSpacing: "-0.1em",
    lineHeight: "0.8em",
  },
};
