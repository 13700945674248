import {ContentBox} from "../components/ContentBox";
import {useParams} from "react-router-dom";
import {projectCoverId, ProjectData, ProjectMap, projectOriginalId} from "../data/ProjectData";
import React from "react";
import {PageNotFoundPage} from "./PageNotFound.page";
import {alpha, Box, Button, Divider, Stack, Typography, useTheme} from "@mui/material";
import {ConfigPanelWidth, Filler, Spacer, useMobile} from "../components/common/Extensions";
import {SocialFooter} from "../components/common/CommonLayout";
import {ProjectTagChip} from "../components/ProjectTagChip";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import {RadiantIconButton} from "../components/RadiantIconButton";
import {useAppDispatch, useAppSelector} from "../components/common/Global";
import {decreaseFontSize, displayNotes, displayTabs, increaseFontSize, resetFontSize, selectDisplayNotes, selectFontSizeMultiplier} from "./common/TabsState";
import {useReactToPrint} from "react-to-print";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {setPageTitle} from "./common/NavigationState";
import {Logo} from "../components/common/Logo";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import PrintIcon from "@mui/icons-material/Print";

// @ts-ignore
const ProjectPageContext = React.createContext<ProjectData>({});

const useProject = () => React.useContext(ProjectPageContext);

export const ProjectPage = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const project = React.useMemo(() => {
    const projectId = params["projectId"];
    if (projectId) return ProjectMap[projectId];
  }, [params]);

  if (project) {
    dispatch(setPageTitle(project.game + " - " + project.song));
    return (
      <ProjectPageContext.Provider value={project}>
        <ProjectTitlePanel />
        <ProjectTabsPanel />
        <ProjectVideosPanel />
        <SocialFooter color="white" />
      </ProjectPageContext.Provider>
    );
  } else {
    return <PageNotFoundPage />;
  }
};

const NarrowedContentPanel = (props: {children: React.ReactNode}) => (
  <ContentBox backgroundColor="white" unlockHeight>
    <Stack
      spacing={1}
      sx={{
        display: "flex",
        maxWidth: ConfigPanelWidth,
        width: ConfigPanelWidth,
      }}
    >
      {props.children}
    </Stack>
  </ContentBox>
);

const ProjectTitlePanel = () => (
  <NarrowedContentPanel>
    <Stack spacing={1}>
      <ProjectSongName />
      <ProjectGameName />
      <Spacer size={2} />
      <ProjectTags />
    </Stack>
  </NarrowedContentPanel>
);

const ProjectSongName = () => {
  const project = useProject();
  const theme = useTheme();
  return (
    <Typography align="center" variant="h1" sx={{color: theme.palette.common.black}}>
      {project.song}
    </Typography>
  );
};

const ProjectGameName = () => {
  const project = useProject();
  const theme = useTheme();
  return (
    <Typography align="center" variant="h2" sx={{color: theme.palette.common.black}}>
      {project.game}
    </Typography>
  );
};

const ProjectTags = () => {
  const project = useProject();
  return (
    <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap" justifyContent="center">
      {project.sortedTags.map((tag, index) => (
        <ProjectTagChip key={tag} tag={tag} />
      ))}
    </Stack>
  );
};

const ProjectTabsPanel = () => {
  return (
    <NarrowedContentPanel>
      <Stack spacing={2}>
        <TabsControls />
        <TabsDivider />
        <ProjectTabsContent print={false} />
      </Stack>
    </NarrowedContentPanel>
  );
};

const ProjectTabsContent = (props: {print: boolean}) => {
  const project = useProject();
  const isMobile = useMobile();
  const tabsSpacing = React.useMemo(() => {
    return props.print || isMobile ? 1 : 3;
  }, [props.print, isMobile]);
  const displayNotes = useAppSelector(selectDisplayNotes);
  const fontSizeMultiplier = useAppSelector(selectFontSizeMultiplier);
  const printSizeMultiplier = React.useMemo(() => {
    return props.print ? fontSizeMultiplier * 0.69 : fontSizeMultiplier;
  }, [props.print, fontSizeMultiplier]);
  return (
    <Stack spacing={tabsSpacing} sx={{display: "block", fontSize: `${printSizeMultiplier}rem`}}>
      {project.tabs.map((paragraph, paragraphIndex) => {
        const lines = paragraph.split("\n").map((line, index) => {
          const nbsp = "\u00A0";
          const space = " ";
          let lineWithBreaks = "";
          for (let index = 0; index < line.length; index++) {
            const char = line.charAt(index);
            let noteReplacement = "";
            let tabReplacement = "";
            if (index == 0) {
              // at the start - do not add a space
              noteReplacement = char;
              tabReplacement = char;
            } else if (char == " ") {
              noteReplacement = nbsp + char;
              tabReplacement = nbsp + space + char;
            } else if (char == "-") {
              // before dashes and dots - add a non breaking space
              noteReplacement = nbsp + char;
              tabReplacement = nbsp + char + nbsp + nbsp;
            } else if (char == ".") {
              noteReplacement = char;
              tabReplacement = char + nbsp + nbsp + nbsp;
            } else if (index > 0 && char == "'") {
              noteReplacement = char;
              tabReplacement = space + nbsp + nbsp + char;
            } else if (index > 0 && line.charAt(index - 1) == "'") {
              // after quotes - add a non breaking space
              noteReplacement = nbsp + char;
              tabReplacement = char;
            } else {
              // before any letter - add a breakable space
              noteReplacement = space + char;
              tabReplacement = space + char;
            }
            if (displayNotes) {
              lineWithBreaks = lineWithBreaks + noteReplacement;
            } else {
              lineWithBreaks = lineWithBreaks + tabReplacement;
            }
          }
          // let lineFormatted = lineWithBreaks;
          // if (!displayNotes) {
          //   lineFormatted = lineWithBreaks
          //     .replaceAll(" ", nbsp + space)
          //     .replaceAll("-", nbsp + "-" + space)
          //     .replaceAll(".", nbsp + "." + nbsp + space)
          //     .replaceAll(/(?<!^)'/gi, nbsp + nbsp + "'");
          // }
          return <TabsLine key={index} text={lineWithBreaks} />;
        });
        const divider = paragraphIndex < project.tabs.length - 1 && <TabsDivider />;
        return (
          <Stack spacing={tabsSpacing} key={paragraphIndex} sx={{pageBreakInside: "avoid"}}>
            {lines}
            {divider}
          </Stack>
        );
      })}
    </Stack>
  );
};

const TabsControls = () => (
  <Stack direction="row" spacing={2} alignItems="center">
    <TabsSizeControls />
    <TabsStyleControls />
    <Filler />
    <TabsPrintButton />
  </Stack>
);

const TabsSizeControls = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const increase = () => dispatch(increaseFontSize());
  const decrease = () => dispatch(decreaseFontSize());
  const reset = () => dispatch(resetFontSize());
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <RadiantIconButton label="Decrease" variant="contained" onClick={decrease}>
        <TextDecreaseIcon />
      </RadiantIconButton>
      <RadiantIconButton label="Increase" variant="contained" onClick={increase}>
        <TextIncreaseIcon />
      </RadiantIconButton>
      <RadiantIconButton label="Reset" variant="contained" onClick={reset}>
        <SettingsBackupRestoreIcon />
      </RadiantIconButton>
    </Stack>
  );
};

const TabsStyleControls = () => {
  const shouldDisplayNotes = useAppSelector(selectDisplayNotes);
  const dispatch = useAppDispatch();
  const clickDisplayTabs = () => dispatch(displayTabs());
  const clickDisplayNotes = () => dispatch(displayNotes());
  const selectVariant = (expectingNotes: boolean) => {
    return (expectingNotes == shouldDisplayNotes) ? "outlined" : "contained";
  };
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <RadiantIconButton label="Show Tabs" variant={selectVariant(false)} onClick={clickDisplayTabs}>
        <Logo sx={{height: 20, width: 20}} />
      </RadiantIconButton>
      <RadiantIconButton label="Show Notes" variant={selectVariant(true)} onClick={clickDisplayNotes}>
        <MusicNoteIcon />
      </RadiantIconButton>
    </Stack>
  );
};
const TabsPrintButton = () => {
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({content: () => componentRef.current});
  const isMobile = useMobile();
  const printIcon = (
    <RadiantIconButton label="Print Tabs" variant="contained" onClick={handlePrint}>
      <PrintIcon />
    </RadiantIconButton>
  );
  const printButton = (
    <Button variant="contained" onClick={handlePrint}>
      Print Tabs
    </Button>
  );
  return (
    <>
      {isMobile ? printIcon : printButton}
      <Box sx={{display: "none"}}>
        <PrintView ref={componentRef} />
      </Box>
    </>
  );
};

class PrintView extends React.PureComponent {
  render() {
    return (
      <Stack sx={{"@page": {margin: "35px !important"}, padding: "15px", fontSize: "0.7rem"}}>
        <ProjectSongName />
        <ProjectGameName />
        <Spacer size={4} />
        <ProjectTabsContent print={true} />
      </Stack>
    );
  }
}

const TabsLine = (props: {text: string}) => {
  const displayNotes = useAppSelector(selectDisplayNotes);
  return (<Typography variant={displayNotes ? "notes" : "tabs"} sx={{maxWidth: "calc(100vw - 20px)"}}>{props.text}</Typography>);
};

const TabsDivider = () => {
  const theme = useTheme();
  return (
    <Box sx={{paddingY: theme.spacing(1)}}>
      <Divider sx={{borderColor: alpha(theme.palette.common.black, 0.5), borderWidth: 1}} />
    </Box>
  );
};

const ProjectVideosPanel = () => {
  const project = useProject();
  return (
    <NarrowedContentPanel>
      <Stack spacing={2}>
        <ProjectVideoTitle title="OCWALK Demonstration" link={project.coverUrl} />
        <EmbedYoutube videoId={projectCoverId(project)} />
        <Spacer size={1} />
        <ProjectVideoTitle title="Original Song" link={project.originalUrl} />
        <EmbedYoutube videoId={projectOriginalId(project)} />
      </Stack>
    </NarrowedContentPanel>
  );
};

const ProjectVideoTitle = (props: {title: string; link: string}) => {
  const theme = useTheme();
  const isMobile = useMobile();
  return (
    <Stack direction="row" spacing={2} sx={{alignItems: "center"}}>
      <Typography variant="body1" textTransform="capitalize" sx={{color: theme.palette.common.black}}>
        {props.title}:
      </Typography>
      <Filler />
      <Button variant="contained" component="a" href={props.link} target="_blank" startIcon={<OpenInNewIcon />}>
        {isMobile ? "Open" : "Open in new tab"}
      </Button>
    </Stack>
  );
};

const EmbedYoutube = (props: {videoId: string}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        boxShadow: theme.shadows[5],
        width: "100%",
        paddingTop: "56.25%",
        overflow: "hidden",

        ["iframe"]: {
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%",
        },
      }}
    >
      <iframe src={`https://www.youtube-nocookie.com/embed/${props.videoId}?vq=hd1080&rel=0`} allowFullScreen title="Embedded youtube" frameBorder="0" />
    </Box>
  );
};
