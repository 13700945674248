import React from "react";
import {ContentBox} from "../../components/ContentBox";
import {ConfigPanelWidth} from "../../components/common/Extensions";
import {Accordion, AccordionDetails, AccordionSummary, Button, InputAdornment, Paper, Slider, Stack, TextField, Typography} from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";
import OpacityIcon from "@mui/icons-material/Opacity";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ColorHSL, HEX2HSL, HSL2HEX, ShiftHSL} from "../../utils/colors";
import TagIcon from "@mui/icons-material/Tag";
import {StackProps} from "@mui/material/Stack/Stack";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import {RootState, useAppDispatch, useAppSelector} from "../../components/common/Global";
import {addSecondaryColor, decrementShadesAmount, exportState, importState, incrementShadesAmount, removeSecondaryColor, selectColorAmount, selectDark, selectExponentialColor, selectExponentialShift, selectLight, selectLinearColor, selectLinearShift, selectPaletteResult, selectPrimary, selectSecondaryAmount, selectSecondaryColor, selectSecondaryShift, selectShadesAmount, setDarkHSL, setExponentialShift, setLightHSL, setLinearShift, setPrimaryHSL, setSecondaryShift} from "./PaletterState";
import {Action, ActionCreator, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {indexRange} from "../../utils/common";
import {useDebouncedCallback} from "use-debounce";

export const PaletterPage = () => (
  <>
    <PaletterPanel />
  </>
);

const PaletterPanel = () => (
  <ContentBox unlockHeight>
    <PaletterBox />
  </ContentBox>
);

const PaletterBox = () => {
  const secondaryAmount = useAppSelector(selectSecondaryAmount);
  return (
    <Stack
      spacing={1}
      sx={{
        display: "flex",
        maxWidth: ConfigPanelWidth,
        width: ConfigPanelWidth,
      }}
    >
      <PaletteSettings />
      <ColorPicker title="primary color" colorSelector={selectPrimary} colorDispatch={setPrimaryHSL} />
      {indexRange(secondaryAmount).map(index => {
        const shiftSelector = selectSecondaryShift(index);
        const colorSelector = selectSecondaryColor(index);
        const setShiftAction = (value: ShiftHSL) => setSecondaryShift({index: index, value: value});
        return <ColorShifter key={index} title={`Secondary Color ${index + 1}`} shiftSelector={shiftSelector} colorSelector={colorSelector} setShiftAction={setShiftAction} />;
      })}
      <ColorPicker title="dark shift color" onlyHue colorSelector={selectDark} colorDispatch={setDarkHSL} />
      <ColorPicker title="light shift color" onlyHue colorSelector={selectLight} colorDispatch={setLightHSL} />
      <ColorShifter precise title="Linear Shade Shift" shiftSelector={selectLinearShift} colorSelector={selectLinearColor} setShiftAction={setLinearShift} />
      <ColorShifter precise title="Exponential Shade Shift" shiftSelector={selectExponentialShift} colorSelector={selectExponentialColor} setShiftAction={setExponentialShift} />
      <PaletteResult />
    </Stack>
  );
};

const PaletteSettings = () => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography textTransform="capitalize">Palette Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1} paddingX={1}>
          <ImportBox />
          <PaletteHeightBox />
          <PaletteWidthBox />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const ImportBox = () => {
  const [value, setValue] = React.useState("");
  const dispatch = useAppDispatch();
  const importStateCode = () => dispatch(importState(value));
  const exportStateCode = () => dispatch(exportState());
  return (
    <HorizontalStack>
      <LimitedWidthStack>
        <ImportExportIcon />
        <Typography>Import / Export:</Typography>
      </LimitedWidthStack>
      <TextField value={value} onChange={event => setValue(event.target.value)} size="small" sx={{flexGrow: 1}} />
      <Button variant="contained" onClick={importStateCode}>
        Import
      </Button>
      <Button variant="contained" onClick={exportStateCode}>
        Export
      </Button>
    </HorizontalStack>
  );
};

const PaletteHeightBox = () => (
  <HorizontalStack>
    <LimitedWidthStack>
      <FormatListNumberedIcon />
      <Typography>Color Count:</Typography>
    </LimitedWidthStack>
    <NumberInput amountSelector={selectColorAmount} addAction={addSecondaryColor} removeAction={removeSecondaryColor} />
  </HorizontalStack>
);

const PaletteWidthBox = () => (
  <HorizontalStack>
    <LimitedWidthStack>
      <BrightnessMediumIcon />
      <Typography>Color Shades:</Typography>
    </LimitedWidthStack>
    <NumberInput amountSelector={selectShadesAmount} addAction={incrementShadesAmount} removeAction={decrementShadesAmount} />
  </HorizontalStack>
);

type NumberInputProps = {
  amountSelector: (state: RootState) => number;
  addAction: ActionCreator<any>;
  removeAction: ActionCreator<any>;
};

const NumberInput = (props: NumberInputProps) => {
  const amount = useAppSelector(props.amountSelector);
  const dispatch = useAppDispatch();
  const addDispatch = () => dispatch(props.addAction());
  const removeDispatch = () => dispatch(props.removeAction());
  const buttonSx = {["& .MuiButton-startIcon"]: {margin: 0}};
  return (
    <Stack direction="row" spacing={1}>
      <Button variant="contained" startIcon={<RemoveIcon />} sx={buttonSx} onClick={removeDispatch} />
      <TextField size="small" contentEditable={false} value={amount} sx={{width: 100}} inputProps={{min: 0, sx: {textAlign: "center"}}} />
      <Button variant="contained" startIcon={<AddIcon />} sx={buttonSx} onClick={addDispatch} />
    </Stack>
  );
};

type ColorPickerProps = {
  title: string;
  colorSelector: (state: RootState) => ColorHSL;
  colorDispatch: ActionCreatorWithPayload<ColorHSL>;
  onlyHue?: boolean;
};

const ColorPicker = (props: ColorPickerProps) => {
  const color = useAppSelector(state => props.colorSelector(state));
  const dispatch = useAppDispatch();
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" spacing={2}>
          <ColorDisplay color={color} />
          <Typography textTransform="capitalize">{props.title}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1} paddingX={1}>
          <ColorTextBox onlyHue={!!props.onlyHue} color={color} colorDispatch={value => dispatch(props.colorDispatch(value))} />
          <ColorSlider title="hue" icon={<PaletteIcon />} min={0} max={360} step={1} value={color.hue} onChange={value => dispatch(props.colorDispatch({...color, hue: value}))} />
          {!props.onlyHue && (
            <>
              <ColorSlider title="saturation" icon={<OpacityIcon />} min={0} max={100} step={1} value={color.saturation} onChange={value => dispatch(props.colorDispatch({...color, saturation: value}))} />
              <ColorSlider title="luminosity" icon={<Brightness5Icon />} min={0} max={100} step={1} value={color.luminosity} onChange={value => dispatch(props.colorDispatch({...color, luminosity: value}))} />
            </>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

type ColorShifterProps = {
  title: string;
  shiftSelector: (state: RootState) => ShiftHSL;
  colorSelector: (state: RootState) => ColorHSL;
  setShiftAction: (value: ShiftHSL) => Action;
  precise?: boolean;
};

const ColorShifter = (props: ColorShifterProps) => {
  const dispatch = useAppDispatch();
  const color = useAppSelector(props.colorSelector);
  const shift = useAppSelector(props.shiftSelector);
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" spacing={2}>
          <ColorDisplay color={color} />
          <Typography textTransform="capitalize">{props.title}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1} paddingX={1}>
          <ColorSlider title="hue shift" icon={<PaletteIcon />} min={props.precise ? 0 : -360} max={props.precise ? 100 : 360} step={props.precise ? 0.1 : 1} value={shift.hueShift} onChange={value => dispatch(props.setShiftAction({...shift, hueShift: value}))} />
          <ColorSlider title="saturation shift" icon={<OpacityIcon />} min={props.precise ? 0 : -100} max={100} step={props.precise ? 0.1 : 1} value={shift.saturationShift} onChange={value => dispatch(props.setShiftAction({...shift, saturationShift: value}))} />
          <ColorSlider title="luminosity shift" icon={<Brightness5Icon />} min={props.precise ? 0 : -100} max={100} step={props.precise ? 0.1 : 1} value={shift.luminosityShift} onChange={value => dispatch(props.setShiftAction({...shift, luminosityShift: value}))} />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const ColorTextBox = (props: {onlyHue: boolean; color: ColorHSL; colorDispatch: (value: ColorHSL) => void}) => (
  <HorizontalStack>
    <LimitedWidthStack>
      <TagIcon />
      <Typography>Hex Color:</Typography>
    </LimitedWidthStack>
    <TextField
      size="small"
      value={HSL2HEX(props.color).slice(1)}
      onChange={event => {
        const color = HEX2HSL("#" + event.target.value);
        if (color) props.colorDispatch(color);
      }}
      sx={{flexGrow: 1}}
      InputProps={{
        startAdornment: <InputAdornment position="start">#</InputAdornment>,
      }}
    />
  </HorizontalStack>
);

const ColorSlider = (props: {title: string; icon: React.ReactNode; min: number; max: number; step: number; value: number; onChange: (value: number) => void}) => {
  const [innerValue, setInnerValue] = React.useState(0);
  const debounced = useDebouncedCallback(value => props.onChange(value), 50);
  React.useEffect(() => setInnerValue(props.value), [props.value]);
  return (
    <HorizontalStack>
      <LimitedWidthStack>
        {props.icon}
        <Typography textTransform="capitalize">{props.title}:</Typography>
        <Typography>{props.value}</Typography>
      </LimitedWidthStack>
      <Slider
        aria-label={props.title}
        value={innerValue}
        min={props.min}
        max={props.max}
        step={props.step}
        onChange={(event, value) => {
          debounced(value as number);
          setInnerValue(value as number);
        }}
      />
    </HorizontalStack>
  );
};

const HorizontalStack = (props: StackProps) => (
  <Stack spacing={2} direction="row" alignItems="center" {...props}>
    {props.children}
  </Stack>
);

const LimitedWidthStack = (props: StackProps) => (
  <HorizontalStack sx={{width: 300, flexShrink: 0}} {...props}>
    {props.children}
  </HorizontalStack>
);

const ColorDisplay = (props: {color: ColorHSL}) => (
  <Paper
    elevation={2}
    sx={{
      width: 32,
      height: 32,
      borderColor: "#151d28",
      borderWidth: 1,
      borderStyle: "solid",
      backgroundColor: HSL2HEX(props.color),
      boxSizing: "border-box",
    }}
  />
);

const PaletteResult = () => {
  const palette = useAppSelector(selectPaletteResult);
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography textTransform="capitalize">Palette Output</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1} paddingX={1}>
          {palette.map((row, index) => (
            <Stack key={index} spacing={1} direction="row">
              {row.map((color, index) => (
                <ColorDisplay key={index} color={color} />
              ))}
            </Stack>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
